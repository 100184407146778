import React, { useEffect } from 'react';
import ReactTable from '../components/tables/ReactTable/ReactTable';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import PaperContainer from '../components/Containers/PaperContainer';
import { fetchProcessingDataInFull } from '../store/reducers/slices/processingTableSlice';
import {
  getTaskData,
  getAllTaskTypeCounts, // contains totals
} from '../store/reducers/slices/dashboardDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DefaultColumnFilter from '../components/tables/ReactTable/DefaultColumnFilter';
import SelectColumnFilterWithTotals from '../components/tables/ReactTable/SelectColumnFilterWithTotals';
import SelectColumnFilterWithoutTimev2 from '../components/tables/ReactTable/SelectColumnFilterWithoutTimev2';

const columnsForTable = [
  {
    Header: 'Task queue id',
    id: 'task_queue_id',
    accessor: (t) => t.task_queue_id,
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'Type name',
    id: 'task_type_name',
    accessor: (t) => {
      return `${t.task_type_name}`;
    },
    Filter: SelectColumnFilterWithTotals,
    filter: 'exactTextCase', // adds exact text filtering - https://github.com/TanStack/react-table/discussions/2859
  },
  {
    accessor: (originalRow) =>
      moment(
        originalRow['task_date_requested'],
        'YYYY-MM-DD HH:mm:ss'
      ).toDate(),
    id: 'task_date_requested',
    Header: 'Date requested',
    Filter: SelectColumnFilterWithoutTimev2,
    Cell: (params) => {
      return (
        <>
          {moment(params.row.values.task_date_requested, 'DD-MM-YYYY HH:mm:ss')
            .format('DD/MM/YYYY, HH:mm:ss')
            .toString()}
        </>
      );
    },
    sortType: 'datetime',
  },
];

const ProcessingTaskReactTableView = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.manageGraphData);
  const { data, paginationTaskTotal, selectedTaskType, loading } = useSelector(
    (state) => state.manageProcessingTable
  );

  useEffect(() => {
    dispatch(getTaskData({}));
    dispatch(
      getAllTaskTypeCounts({ startDate: new Date(), endDate: new Date() })
    );
    dispatch(
      fetchProcessingDataInFull({
        type_id: selectedTaskType,
        start_date: startDate,
        end_date: endDate,
      })
    );
  }, [dispatch, endDate, selectedTaskType, startDate]);

  return (
    <PaperContainer>
      <Box style={{ marginBottom: '1em' }}>
        <Typography variant='h2' style={{ textAlign: 'center' }}>
          Processing table
        </Typography>
      </Box>
      <ReactTable
        columns={columnsForTable}
        data={data}
        selectedTaskType={selectedTaskType}
        paginationTaskTotal={paginationTaskTotal}
      />
      {loading && (
        <Box
          width='100%'
          height='90vh'
          display='flex'
          justifyContent='center'
          alignItems='center'
          zIndex='30'
        >
          <CircularProgress size='5rem' />
        </Box>
      )}
    </PaperContainer>
  );
};

export default ProcessingTaskReactTableView;
