import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchLogDnaDespatchItem,
  fetchLogDnaUpsItem,
  checkCartonBarcodeHasLabelGenerated,
  resetLastUpsLabelGeneratedCheck,
  fetchCheckDespatchNoteGenerated,
  resetLastDespatchLabelGeneratedCheck,
} from '../store/reducers/slices/monitoringSlice';
import { BarcodeGridItem } from '../components/barcode_search/barcodeGridItem';
import { monitoringStyling } from '../styles/MonitoringStyling';

const {
  REACT_APP_DESPATCH_UPS_HOST,
  REACT_APP_DESPATCH_APP,
  REACT_APP_UPS_APP,
} = process.env;

export const SearchBarcodeView = ({
  setIsSelectedAnalytics,
  setIsSelectedSettings,
  setIsSelectedSearch,
  setIsSelectedMonitoring,
  setIsSelectedBarcodeSearch,
}) => {
  const dispatch = useDispatch();

  const {
    checkbarCodeLoading,
    despatchCodeLoading,
    searchLoading,
    searchedDespatchItem,
    searchedUpsItem,
    logopackLoading,
    despatchNoteLoading,
  } = useSelector((state) => state.manageMonitoringData);

  const classes = monitoringStyling();

  const [upsBarcode, setUpsBarcode] = useState('');
  const [despatchesBarCode, setDespatchesBarCode] = useState('');
  const [upsBarcodeValidation, setUpsBarcodeValidation] = useState(false);
  const [despatchesBarcodeValidation, setDespatchesBarcodeValidation] =
    useState(false);
  const [upsSearched, setUpsSearched] = useState(false);
  const [despatchesSearched, setDespatchesSearched] = useState(false);
  const [hasDespatchNoteGenerated, setHasDespatchNoteGenerated] =
    useState(false);
  const [hasUpsLabelGenerated, setHasUpsLabelGenerated] = useState(false);
  const [isDespatchId, setIsDespatchId] = useState(false);

  useEffect(() => {
    setIsSelectedAnalytics(false);
    setIsSelectedSettings(false);
    setIsSelectedSearch(false);
    setIsSelectedMonitoring(false);
    setIsSelectedBarcodeSearch(true);
  }, [
    setIsSelectedAnalytics,
    setIsSelectedBarcodeSearch,
    setIsSelectedMonitoring,
    setIsSelectedSearch,
    setIsSelectedSettings,
  ]);

  const validateBarcode = (barcode) => {
    const regCheck = /[a-zA-Z]/g;
    const barcodeCheck = regCheck.test(barcode);

    if (barcode === '' || barcodeCheck) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpsBarcodeSearch = (e) => {
    e.preventDefault();
    setHasUpsLabelGenerated(false);

    const validated = validateBarcode(upsBarcode);
    if (!validated) {
      // if input contains characters... or empty..
      setUpsBarcodeValidation(true);
      return;
    }

    setUpsBarcodeValidation(false);
    dispatch(
      checkCartonBarcodeHasLabelGenerated({ searchTerm: `${upsBarcode}` })
    ).then((res) => {
      setUpsSearched(true);
      let upsLabel;
      if (res.payload[0]?.label_generated !== undefined) {
        upsLabel = res.payload[0].label_generated;
        setHasUpsLabelGenerated(upsLabel);
      } else {
        setHasUpsLabelGenerated(false);
        upsLabel = false;
      }

      if (!upsLabel || upsLabel === undefined) {
        // then call logdna api to get details for that false item
        dispatch(
          fetchLogDnaUpsItem({
            query: `${upsBarcode}`,
            hosts: `${REACT_APP_DESPATCH_UPS_HOST}`,
            apps: `${REACT_APP_DESPATCH_APP}`,
          })
        );
      }
    });
  };

  const handleDespatchesBarcodeSearch = (e) => {
    e.preventDefault();
    setHasDespatchNoteGenerated(false);
    setDespatchesSearched(false);

    const validated = validateBarcode(despatchesBarCode);
    if (!validated) {
      setDespatchesBarcodeValidation(true);
      return;
    }

    setDespatchesBarcodeValidation(false);
    try {
      dispatch(
        fetchCheckDespatchNoteGenerated({
          searchTerm: `${despatchesBarCode}`,
          isDespID: isDespatchId,
        })
      ).then((res) => {
        let despatchNote = 0;
        if (res.error) {
          setHasDespatchNoteGenerated(res.payload.isLabelGenerated); // false because there is an error
          setDespatchesSearched(true);
          return;
        }

        if (!res.error) {
          despatchNote = res.payload;
          setHasDespatchNoteGenerated(res.payload);
          setDespatchesSearched(true);
          if ((!despatchNote || despatchNote === undefined) && isDespatchId) {
            // then call logdna api to get details for that false item
            dispatch(
              fetchLogDnaDespatchItem({
                query: `${despatchesBarCode}`,
                hosts: `${REACT_APP_DESPATCH_UPS_HOST}`,
                apps: `${REACT_APP_UPS_APP}`,
              })
            );
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container spacing={2} className={classes.searchBarcodeContainer}>
      <BarcodeGridItem
        header={'Search for label'}
        barcodeValidation={upsBarcodeValidation}
        setBarcodeValidation={setUpsBarcodeValidation}
        barcode={upsBarcode}
        setBarcode={setUpsBarcode}
        searchForItemById={searchedUpsItem}
        handleBarcodeSearch={handleUpsBarcodeSearch}
        loading={checkbarCodeLoading}
        searched={upsSearched}
        setSearched={setUpsSearched}
        hasLabelGenerated={hasUpsLabelGenerated}
        directoryLoading={logopackLoading}
        fetchSearchItem={fetchLogDnaUpsItem}
        resetLabelCheckAfterSearch={resetLastUpsLabelGeneratedCheck}
      />
      <BarcodeGridItem
        header={'Search despatch note'}
        barcodeValidation={despatchesBarcodeValidation}
        setBarcodeValidation={setDespatchesBarcodeValidation}
        handleBarcodeSearch={handleDespatchesBarcodeSearch}
        loading={despatchCodeLoading}
        barcode={despatchesBarCode}
        setBarcode={setDespatchesBarCode}
        searched={despatchesSearched}
        noteSearchLoading={despatchNoteLoading}
        setSearched={setDespatchesSearched}
        hasLabelGenerated={hasDespatchNoteGenerated}
        searchLoading={searchLoading}
        searchForItemById={searchedDespatchItem}
        fetchSearchItem={fetchLogDnaDespatchItem}
        resetLabelCheckAfterSearch={resetLastDespatchLabelGeneratedCheck}
        despatchSpecific={true}
        isDespatchId={isDespatchId}
        setIsDespatchId={setIsDespatchId}
      />
    </Grid>
  );
};
