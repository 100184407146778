import React from "react";
import { Select, MenuItem } from "@material-ui/core";

const SelectColumnFilter = (props) => {
  const {
    column: { setFilter, preFilteredRows, id, filterValue },
  } = props;

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(`${row.values[id]}`);
    });
    switch (id) {
      case "date":
        return [...options.values()].sort().reverse();
      default:
        return [...options.values()].sort();
    }
  }, [id, preFilteredRows]);

  return (
    <Select
      fullWidth
      onChange={(e) => {
        const { value } = e.target;
        setFilter(value === "select" ? undefined : value);
      }}
      value={filterValue || "select"}
    >
      <MenuItem value="select">Show all</MenuItem>
      {options.map((o) => {
        return (
          <MenuItem key={o} value={o}>
            {o}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectColumnFilter;
