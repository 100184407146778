import {
  Box,
  Typography,
} from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import LoadingSpinner from '../global/LoadingSpinner';
import WarningIcon from '@material-ui/icons/Warning';

export const PlcStatusInnerItem = ({ plcData, plcLoading, toggled, classes }) => {
  return (
    <>
      {plcData && !plcLoading && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems={"center"} className={classes.cardItemContainer} height={toggled ? "60vh" : ''}>
          <Box style={{ alignItems: "center", alignContent: "center", justifyContent: "center" }} className={toggled ? classes.cardToggled : classes.cardUntoggled}>
            {plcData.success ?
              <CheckCircleOutlineIcon className={toggled ? classes.plcSuccessToggled : classes.plcSuccessUnToggled} />
              : Object.keys(plcData).length === 0 ? <WarningIcon className={toggled ? classes.plcWarningToggled : classes.plcWarningUnToggled} />
                : <CancelIcon className={toggled ? classes.plcErrorToggled : classes.plcErrorUnToggled} />}
          </Box>
          <Typography variant={toggled && plcData?.message?.length < 3 ? "h2" : "h4"}>
            {plcData?.message ? plcData.message : '404 Not Found.'}
          </Typography>
        </Box>
      )}

      {plcData && plcLoading && (
        <LoadingSpinner />
      )}
    </>
  )
}