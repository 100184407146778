import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Container,
  Button,
  Switch,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import cron from 'cron-validate';
import './config.css';
import { addAlert } from '../../store/reducers/slices/configSlice';
import { useDispatch } from 'react-redux';
import {
  postConfigCronAdd,
  postConfigCronEdit,
  deleteConfigCron,
} from '../../store/reducers/slices/configSlice';

export default function AddCronJob({
  cronJob,
  closeModal,
  mode,
  getScheduleData,
  setAlertData,
}) {
  const dispatch = useDispatch();
  const [id] = useState(cronJob.id);
  const [name, setName] = useState(cronJob.name);
  const [schedule, setSchedule] = useState(cronJob.schedule);
  const [active, setActive] = useState(cronJob.active);

  const [cronPopup, setCronPopup] = useState({
    cronError: false,
    cronMessage: '',
  });
  const [askDelete, setAskDelete] = useState(false);

  const createCron = async (event) => {
    event.preventDefault();

    let checkCron = cron(schedule);

    let cronError = checkCron.isError();
    if (cronError === true) {
      let error = checkCron.getError();
      setCronPopup({ cronError: true, cronMessage: error[0] });
    } else {
      setCronPopup({ cronError: false, cronMessage: '' });
      const cronData = {
        id: id,
        name: name,
        schedule: schedule,
        active: active,
      };
      if (mode === 'Add') {
        dispatch(postConfigCronAdd(cronData)).then(() => {
          getScheduleData();
          closeModal();
        });
      } else if (mode === 'Edit') {
        dispatch(postConfigCronEdit(cronData)).then(() => {
          getScheduleData();
          closeModal();
        });
      }
    }
  };

  const askDeleteJob = () => {
    setAskDelete(true);
  };

  const cancelDeleteJob = () => {
    setAskDelete(false);
    dispatch(
      addAlert({
        open: true,
        severity: 'warning',
        message: 'The cron job has not been deleted',
      })
    );
  };

  const deleteJob = async () => {
    dispatch(
      deleteConfigCron({
        id,
      })
    ).then(() => {
      getScheduleData();
      closeModal();
    });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleActive = (event) => {
    setActive(event.target.checked);
  };

  const handleCron = (event) => {
    setSchedule(event.target.value);
  };
  return (
    <Container fixed>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          boxShadow: 'rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '0px solid rgba(0, 0, 0, 0.125)',
          borderRadius: '1rem',
          padding: '30px',
          minWidth: '350px',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100%'
        >
          <Box>
            <Typography variant='h5'>
              {mode === 'Add' ? 'Add a new Job' : 'Edit Job'}
            </Typography>
          </Box>
          <form
            onSubmit={createCron}
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              marginTop='1.5em'
            >
              <TextField
                required
                id='outlined-basic'
                name='jobName'
                label='Job Name'
                variant='outlined'
                fullWidth
                value={name}
                onChange={handleNameChange}
              />
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              marginTop='1.5em'
            >
              <TextField
                required
                id='outlined-basic'
                name='cron'
                label='Cron'
                variant='outlined'
                value={schedule}
                onChange={handleCron}
                style={{ marginRight: '20px', maxWidth: '180px' }}
                error={cronPopup.cronError}
                helperText={
                  cronPopup.cronError
                    ? cronPopup.cronMessage.split('.')[0]
                    : null
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={handleActive}
                    color='primary'
                    name='active'
                    inputProps={{ 'aria-label': 'Active' }}
                  />
                }
                label='Active'
              />
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
              marginTop='2em'
            >
              {askDelete ? (
                <>
                  <Button
                    variant='contained'
                    style={{ backgroundColor: '#DD3333', color: 'white' }}
                    onClick={deleteJob}
                  >
                    Confirm Delete
                  </Button>
                  <Button
                    style={{ marginLeft: '5px' }}
                    variant='contained'
                    color='primary'
                    onClick={cancelDeleteJob}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{ marginRight: '5px' }}
                    variant='contained'
                    color='primary'
                    type='submit'
                  >
                    {mode === 'Edit' ? 'Update Job' : 'Submit Job'}
                  </Button>
                  {mode === 'Edit' ? (
                    <Button
                      style={{ marginLeft: '5px' }}
                      variant='contained'
                      color='primary'
                      onClick={askDeleteJob}
                    >
                      Delete Job
                    </Button>
                  ) : null}
                </>
              )}
            </Box>
          </form>
        </Box>
      </Grid>
    </Container>
  );
}
