import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import {
  GET_MONITORING_DATA,
  GET_LAST_LABEL_GENERATED_DATE_UPS,
  CHECK_CARTON_BARCODE_HAS_LABEL_GENERATED_UPS,
  CHECK_DESPATCH_NOTE_GENERATED,
  GET_LOG_UPS_INFO,
  GET_LOG_UPS_ERROR,
  GET_LOG_DESPATCH_INFO,
  GET_LOG_DESPATCH_ERROR,
  GET_LOG_KEYMAS_ERROR_DATA,
  GET_LOG_KEYMAS_INFO_DATA,
  GET_DESPATCH_LAST_GENERATED_LABEL,
  CHECK_IF_LABEL_FOR_REGENERATION_IS_PREPACK,
  REGENERATE_LABEL,
} from '../../types';
import axios from 'axios';
import moment from 'moment';

const { REACT_APP_API_BASE_URI, REACT_APP_LOGDNA_API } = process.env;
const params = {
  headers: {
    deviceToken: '0e07b48d-b30f-4e02-b554-f23aad4c4b35',
    'Access-Control-Allow-Origin': '*',
    'x-jwt-token':
      'eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds',
  },
};

const manageSearchedItem = (data) => {
  const items = [...data];
  const managedItems = [];
  items.forEach((i) => {
    managedItems.push({
      formattedLine: `${i._line} - ${moment(i._ts).fromNow()}`,
      _ts: i._ts,
    });
  });

  managedItems.sort((a, b) => (a._ts !== b._ts ? (a._ts < b._ts ? 1 : -1) : 0)); // asc or desc

  const item = data[0];
  item.formattedLines = [...managedItems];

  return item;
};

const manageData = (data, item) => {
  const checkItemExists = current(data).some(
    (label) => label?._id === item?._id
  );
  if (!checkItemExists) {
    if (current(data).length >= 2) {
      current(data).forEach((i, index) => {
        // map maybe?
        if (i.level === item.level && i._id !== item._id) {
          item._ts > i._ts && data.splice(index, 1, item); // remove and replace
        }
      });
    }
    if (current(data).length < 2 && current(data).length > 0) {
      const currentItem = data[0];
      if (currentItem.level !== item.level && currentItem._id !== item._id) {
        data.unshift(item);
      } else if (
        // one of them does not have a level -> will need to look at and find the closest thing to it
        currentItem.level === item.level &&
        item._ts > currentItem._ts
      ) {
        data.splice(0, 1, item);
      }
    } else if (current(data).length === 0) {
      data.unshift(item);
    }
  }

  data.forEach((i) => {
    i.formattedTime = moment(i._ts).format('DD/MM/YYYY, HH:mm:ss');
    i.timeFromLastGeneratedLabel = moment(i._ts).fromNow();
    i.timeDifference = moment().diff(i._ts, 'minutes');
  });

  // sort data in asc based on level type
  return (data = [
    ...data.sort((a, b) =>
      a.level !== b.level ? (a.level < b.level ? -1 : 1) : 0
    ),
  ]);
};

// getLastUpdateForDataToKeymas
export const getLastUpdateForDataToKeymas = createAsyncThunk(
  'DATA_SENT_T0_KEYMAS_FROM_DB',
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getLastUpdateForDataToKeymas`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lastSentDataToKeymas;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

// searchLogDnaUpsItem
export const fetchLogDnaUpsItem = createAsyncThunk(
  'SEARCH_UPS_ITEM_LOG_DNA',
  async (dataParams, { rejectWithValue }) => {
    const { query } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/searchLogDnaUpsItem?query=${query}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      if (response.data.lines.length > 0) {
        return response.data.lines;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaDespatchItem = createAsyncThunk(
  'SEARCH_DESPATCH_ITEM_LOG_DNA',
  async (dataParams, { rejectWithValue }) => {
    const { query } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/searchLogDnaDespatchItem?query=${query}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

// statusCheckLogopakDirectory

export const fetchLogopakDirectoryStatus = createAsyncThunk(
  'CHECK_FOLDER_STATUS',
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/statusCheckLogopakDirectory`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }

      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

// statusCheckDespatchPrintDirectory
export const fetchCheckDespatchPrintDirectory = createAsyncThunk(
  'CHECK_DESPATCH_PRINT_FOLDER_STATUS',
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/statusCheckDespatchPrintDirectory`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }

      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaUpsInfo = createAsyncThunk(
  GET_LOG_UPS_INFO,
  async (dataParams, { rejectWithValue }) => {
    const { hosts } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaUpsInfo?hosts=${hosts}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaUpsError = createAsyncThunk(
  GET_LOG_UPS_ERROR,
  async (dataParams, { rejectWithValue }) => {
    const { hosts } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaUpsError?hosts=${hosts}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaDespatchInfo = createAsyncThunk(
  GET_LOG_DESPATCH_INFO,
  async (dataParams, { rejectWithValue }) => {
    const { hosts } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaDespatchInfo?hosts=${hosts}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaDespatchError = createAsyncThunk(
  GET_LOG_DESPATCH_ERROR,
  async (dataParams, { rejectWithValue }) => {
    const { hosts } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaDespatchError?hosts=${hosts}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaKeymasInfoLogs = createAsyncThunk(
  GET_LOG_KEYMAS_INFO_DATA,
  async (dataParams, { rejectWithValue }) => {
    const { hosts, query, apps } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaKeymasInfo?hosts=${hosts}&query=${query}&apps=${apps}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchLogDnaKeymasErrorLogs = createAsyncThunk(
  GET_LOG_KEYMAS_ERROR_DATA,
  async (dataParams, { rejectWithValue }) => {
    const { hosts, query, levels, apps } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_LOGDNA_API}logdna/getLogDnaKeymasError?hosts=${hosts}&query=${query}&levels=${levels}&apps=${apps}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.lines;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

// despatch
export const fetchCheckDespatchNoteGenerated = createAsyncThunk(
  CHECK_DESPATCH_NOTE_GENERATED,
  async (dataParams, { rejectWithValue }) => {
    const { searchTerm, isDespID } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/CheckDespatchNoteGenerated?Barcode=${searchTerm}&isDespID=${isDespID}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue({
          error: 'Unable to connect',
          isLabelGenerated: false,
        });
      }
      const item = response.data.checkDespatchNoteGenerated;
      if (item.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue({
        error: 'Unable to connect via catch',
        isLabelGenerated: false,
      });
    }
  }
);

export const fetchDespatchNoteLastCreatedLabel = createAsyncThunk(
  GET_DESPATCH_LAST_GENERATED_LABEL,
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getDespatchNoteLastCreatedLabel`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const resetLastDespatchLabelGeneratedCheck = createAsyncThunk(
  'RESET_LAST_UPS_LABEL_GENERATED_CHECK',
  (arg, thunkAPI) => {
    thunkAPI.dispatch(resetDespatchLabelGeneratedCheck());
    return arg;
  }
);

// despatch

// -- ups
export const getLastLabelGeneratedDate = createAsyncThunk(
  // ups carton barcode check
  GET_LAST_LABEL_GENERATED_DATE_UPS,
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getLastLabelGeneratedDate`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const checkCartonBarcodeHasLabelGenerated = createAsyncThunk(
  // ups carton barcode input check
  CHECK_CARTON_BARCODE_HAS_LABEL_GENERATED_UPS,
  async (dataParams, { rejectWithValue }) => {
    const { searchTerm } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/checkCartonBarcodeHasLabelGenerated?searchTerm=${searchTerm}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data.checkCartonHasLabelGenerated;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const checkIfLabelForRegenerationIsPrepack = createAsyncThunk(
  CHECK_IF_LABEL_FOR_REGENERATION_IS_PREPACK,
  async (dataParams, { rejectWithValue }) => {
    const { searchTerm } = dataParams;
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/checkIfLabelForRegenerationIsPrepack?searchTerm=${searchTerm}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('Unable to connect');
      }
      return response.data;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const regenerateLabel = createAsyncThunk(
  REGENERATE_LABEL,
  async (labelData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/regenerateLabel`,
        labelData,
        params
      );

      if (response.status === 200) {
        return response.status;
      } else {
        return rejectWithValue('Unable to connect');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const fetchPLCStatus = createAsyncThunk(
  'FETCH_PLC_STATUS',
  async (dataParams, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/PLCStatusCheck`,
        params
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Unable to connect');
      }
    } catch (err) {
      console.error('plc status error : ', err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const resetLastUpsLabelGeneratedCheck = createAsyncThunk(
  'RESET_LAST_UPS_LABEL_GENERATED_CHECK',
  (arg, thunkAPI) => {
    thunkAPI.dispatch(resetUpsLabelGeneratedCheck());
    return arg;
  }
);
// ups --

const MonitoringSlice = createSlice({
  name: GET_MONITORING_DATA,
  initialState: {
    upsLabelGeneratorInfo: [],
    DespatchNoteGeneratorInfo: [],
    lastGeneratedLabelValueUps: [],
    hasLabelGeneratedUps: false,
    loading: false,
    upsLogdnaLoading: false,
    despatchLogdnaLoading: false,
    despatchNoteLoading: false,
    dataToKeymasLogdnaLoading: false,
    dataFromKeymasLogdnaLoading: false,
    checkbarCodeLoading: false,
    despatchCodeLoading: false,
    checkDespatchNoteIsGenerated: false,
    searchedDespatchItem: {},
    searchedUpsItem: {},
    despatchNoteLastGeneratedLabel: [],
    keymasLogData: [],
    keymasUpdatesAndErrors: [],
    keymasPostLogData: [],
    logopackStatus: 0,
    logopackLoading: false,
    despatchPrintDirectoryStatus: 0,
    despatchDirectoryLoading: false,
    searchLoading: false,
    lastItemToKeymas: '',
    alertData: { open: false, severity: 'success', message: 'base' },
    currentTime: moment(),
    operationTimesUps: {
      startDate: moment('06:00:00', 'hh:mm:ss'),
      endDate: moment('22:00:00', 'hh:mm:ss'),
      offline: false,
    },
    operationTimesDespatch: {
      startDate: moment('06:00:00', 'hh:mm:ss'),
      endDate: moment('22:00:00', 'hh:mm:ss'),
      offline: false,
    },
    operationTimesDataToKeymas: {
      startDate: moment('06:00:00', 'hh:mm:ss'),
      endDate: moment('22:00:00', 'hh:mm:ss'),
      offline: false,
    },
    operationTImesDataFromKeymas: {
      startDate: moment('06:00:00', 'hh:mm:ss'),
      endDate: moment('22:00:00', 'hh:mm:ss'),
      offline: false,
    },
    plcStatus: {},
    plcLoading: false,
  },
  reducers: {
    resetUpsLabelGeneratorInfo: (state, action) => {
      state.upsLabelGeneratorInfo = [];
    },

    resetDespatchLabelInfo: (state, action) => {
      state.DespatchNoteGeneratorInfo = [];
    },

    falsifySearchLoading: (state, action) => {
      state.searchLoading = false;
    },

    addAlert(state, action) {
      state.alertData = action.payload;
    },
    resetAlert(state) {
      state.alertData = { open: false, severity: 'info', message: '' };
    },
    setUpsLoading(state, action) {
      state.upsLogdnaLoading = action.payload;
    },
    resetUpsLabelGeneratedCheck(state, action) {
      state.hasLabelGeneratedUps = false;
    },
    resetDespatchLabelGeneratedCheck(state, action) {
      state.checkDespatchNoteIsGenerated = false;
    },
    setDespatchLoading(state, action) {
      state.despatchLogdnaLoading = action.payload;
    },
    setDataToKeymasLogdnaLoading(state, action) {
      state.dataToKeymasLogdnaLoading = action.payload;
    },
    setDataFromKeymasLogdnaLoading(state, action) {
      state.dataFromKeymasLogdnaLoading = action.payload;
    },
    setOperationTimesUps(state, action) {
      state.operationTimesUps = action.payload;
    },
    setOperationTimesDespatch(state, action) {
      state.operationTimesDespatch = action.payload;
    },
    setOperationTimesDataToKeymas(state, action) {
      state.operationTimesDataToKeymas = action.payload;
    },
    setOperationTimesDataFromKeymas(state, action) {
      state.operationTImesDataFromKeymas = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getLastUpdateForDataToKeymas
    builder.addCase(getLastUpdateForDataToKeymas.fulfilled, (state, action) => {
      if (action.payload) {
        const item = action.payload;
        item.task_date_completed = moment(item.task_date_completed).fromNow();
        item.task_date_requested = moment(item.task_date_requested).fromNow();

        state.lastItemToKeymas = `${item.task_date_completed} [${item.task_queue_id}]`;
      }
      state.loading = false;
    });

    builder.addCase(getLastUpdateForDataToKeymas.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLastUpdateForDataToKeymas.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchLogDnaUpsInfo.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        const item = action.payload[0];
        state.upsLabelGeneratorInfo = [
          ...manageData(state.upsLabelGeneratorInfo, item),
        ];
      } else {
        state.upsLabelGeneratorInfo = 0;
      }
      state.upsLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaUpsInfo.pending, (state) => {
      state.upsLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaUpsInfo.rejected, (state) => {
      state.upsLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaUpsError.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        const item = action.payload[0];
        state.upsLabelGeneratorInfo = [
          ...manageData(state.upsLabelGeneratorInfo, item),
        ];
        state.upsLogdnaLoading = false;
      }
    });

    builder.addCase(fetchLogDnaUpsError.pending, (state) => {
      state.upsLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaUpsError.rejected, (state) => {
      state.upsLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaDespatchInfo.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        const item = action.payload[0];
        state.DespatchNoteGeneratorInfo = [
          ...manageData(state.DespatchNoteGeneratorInfo, item),
        ];
      } else {
        state.DespatchNoteGeneratorInfo = 0;
      }
      state.despatchLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaDespatchInfo.pending, (state) => {
      state.despatchLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaDespatchInfo.rejected, (state) => {
      state.despatchLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaUpsItem.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.searchedUpsItem = manageSearchedItem(action.payload);
      } else {
        state.searchedUpsItem = false;
      }
      state.searchLoading = false;
    });

    builder.addCase(fetchLogDnaUpsItem.pending, (state) => {
      state.searchLoading = true;
    });

    builder.addCase(fetchLogDnaUpsItem.rejected, (state) => {
      state.searchLoading = false;
    });

    builder.addCase(fetchLogDnaDespatchItem.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.searchedDespatchItem = manageSearchedItem(action.payload);
      } else {
        state.searchedDespatchItem = {};
      }
      state.searchLoading = false;
    });

    builder.addCase(fetchLogDnaDespatchItem.pending, (state) => {
      state.searchLoading = true;
    });

    builder.addCase(fetchLogDnaDespatchItem.rejected, (state) => {
      state.searchLoading = false;
    });

    builder.addCase(fetchLogDnaDespatchError.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        const item = action.payload[0];
        state.DespatchNoteGeneratorInfo = [
          ...manageData(state.DespatchNoteGeneratorInfo, item),
        ];
        state.despatchLogdnaLoading = false;
      }
    });

    builder.addCase(fetchLogDnaDespatchError.pending, (state) => {
      state.despatchLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaDespatchError.rejected, (state) => {
      state.despatchLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaKeymasInfoLogs.fulfilled, (state, action) => {
      // manages INFO for both keymas post and keymas logs
      if (action.payload?.length > 0) {
        const item = action.payload[0];

        if (item._line.includes('Checked for Keymas updates from')) {
          // post data to keymas
          state.keymasPostLogData = [
            ...manageData(state.keymasPostLogData, item),
          ];
        } else {
          state.keymasLogData = [...manageData(state.keymasLogData, item)];
        }
      }

      state.dataToKeymasLogdnaLoading = false;
    });
    builder.addCase(fetchLogDnaKeymasInfoLogs.pending, (state) => {
      state.dataToKeymasLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaKeymasInfoLogs.rejected, (state) => {
      state.dataToKeymasLogdnaLoading = false;
    });

    builder.addCase(fetchLogDnaKeymasErrorLogs.fulfilled, (state, action) => {
      // manages errors for both keymas post and keymas logs
      if (action.payload?.length > 0) {
        const item = action.payload[0];

        if (
          item._line.includes('Error checking for Keymas updates RequestError')
        ) {
          state.keymasPostLogData = [
            ...manageData(state.keymasPostLogData, item),
          ];
        } else {
          state.keymasLogData = [...manageData(state.keymasLogData, item)];
        }

        state.dataToKeymasLogdnaLoading = false;
      }
    });
    builder.addCase(fetchLogDnaKeymasErrorLogs.pending, (state) => {
      state.dataToKeymasLogdnaLoading = true;
    });

    builder.addCase(fetchLogDnaKeymasErrorLogs.rejected, (state) => {
      state.dataToKeymasLogdnaLoading = false;
    });

    builder.addCase(getLastLabelGeneratedDate.fulfilled, (state, action) => {
      state.loading = false;
      const cartonBarcode =
        action.payload.data.lastLabelGeneratedDate.carton_barcode;
      const labelGeneratedDate = moment(
        action.payload.data.lastLabelGeneratedDate.label_generated_date
      ).fromNow();
      const output = `${labelGeneratedDate} [${cartonBarcode}]`;
      state.lastGeneratedLabelValueUps = output;
    });

    builder.addCase(getLastLabelGeneratedDate.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLastLabelGeneratedDate.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(
      fetchCheckDespatchNoteGenerated.fulfilled,
      (state, action) => {
        state.despatchNoteLoading = false;
      }
    );

    builder.addCase(fetchCheckDespatchNoteGenerated.pending, (state) => {
      state.despatchNoteLoading = true;
    });

    builder.addCase(fetchCheckDespatchNoteGenerated.rejected, (state) => {
      state.despatchNoteLoading = false;
    });

    builder.addCase(
      checkCartonBarcodeHasLabelGenerated.fulfilled, // carton for ups
      (state, action) => {
        state.hasLabelGeneratedUps = action.payload;
        state.checkbarCodeLoading = false;
      }
    );

    builder.addCase(checkCartonBarcodeHasLabelGenerated.pending, (state) => {
      // carton for ups
      state.checkbarCodeLoading = true;
    });

    builder.addCase(checkCartonBarcodeHasLabelGenerated.rejected, (state) => {
      // carton for ups
      state.checkbarCodeLoading = false;
    });

    builder.addCase(
      fetchDespatchNoteLastCreatedLabel.fulfilled,
      (state, action) => {
        const latestDespatchData = action.payload.data.lastCreatedDespatchNote;
        const DESPID = latestDespatchData.DESPID;
        const KeymasDespatchNoteCreationTime = moment(
          latestDespatchData.KeymasDespatchNoteCreationTime
        ).fromNow();
        const output = `${KeymasDespatchNoteCreationTime} [${DESPID}]`;

        state.despatchNoteLastGeneratedLabel = output;
        state.despatchCodeLoading = false;
      }
    );

    builder.addCase(fetchDespatchNoteLastCreatedLabel.pending, (state) => {
      state.despatchCodeLoading = true;
    });

    builder.addCase(fetchDespatchNoteLastCreatedLabel.rejected, (state) => {
      state.despatchCodeLoading = false;
    });

    builder.addCase(
      fetchLogopakDirectoryStatus.fulfilled, // carton for ups
      (state, action) => {
        state.logopackStatus = action.payload;
        state.logopackLoading = false;
      }
    );

    builder.addCase(fetchLogopakDirectoryStatus.pending, (state) => {
      // carton for ups
      state.logopackLoading = true;
    });

    builder.addCase(fetchLogopakDirectoryStatus.rejected, (state) => {
      // carton for ups
      state.logopackLoading = false;
    });

    builder.addCase(
      fetchCheckDespatchPrintDirectory.fulfilled,
      (state, action) => {
        state.despatchPrintDirectoryStatus = action.payload;
        state.despatchDirectoryLoading = false;
      }
    );

    builder.addCase(fetchCheckDespatchPrintDirectory.pending, (state) => {
      state.despatchDirectoryLoading = true;
    });

    builder.addCase(fetchCheckDespatchPrintDirectory.rejected, (state) => {
      state.despatchDirectoryLoading = false;
    });

    builder.addCase(fetchPLCStatus.fulfilled, (state, action) => {
      state.plcStatus = action.payload;
      state.plcLoading = false;
    });

    builder.addCase(fetchPLCStatus.pending, (state) => {
      state.plcLoading = true;
    });

    builder.addCase(fetchPLCStatus.rejected, (state) => {
      state.plcLoading = false;
    });
  },
});

export const {
  upsLabelGeneratorInfo,
  DespatchNoteGeneratorInfo,
  resetUpsLabelGeneratorInfo,
  resetUpsLabelGeneratedCheck,
  resetDespatchLabelGeneratedCheck,
  resetDespatchLabelInfo,
  falsifySearchLoading,
  addAlert,
  resetAlert,
  setUpsLoading,
  setDespatchLoading,
  setDataToKeymasLogdnaLoading,
  setOperationTimesUps,
  setOperationTimesDespatch,
  setOperationTimesDataToKeymas,
  setOperationTimesDataFromKeymas,
} = MonitoringSlice.actions;
export default MonitoringSlice.reducer;
