import { Box, Typography, Modal, Button } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  infoModal: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #D8E2E9",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  successModal: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #4BD28F",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalTextDistance: {
    marginTop: "1em",
    marginBottom: "1em",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
}

export const SearchedIdModal = ({ data, handleOpen, handleClose, open }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box style={modalStyle} className={classes.infoModal}>
        <Box padding={"20px"} textAlign="center">
          <Typography variant="h3" className={classes.modalTextDistance}>
            Information
          </Typography>
          {data.formattedLines?.map((line) => {
            return (
              <Typography
                variant="h5"
                style={{ fontWeight: "600" }}
                className={classes.modalTextDistance}
              >
                {line.formattedLine};
              </Typography>
            );
          })}
          <Typography variant="h5" className={classes.modalTextDistance}>
            {data.formattedTime} - {data.timeFromLastGeneratedLabel}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
