import { makeStyles } from "@material-ui/core";

export const trafficCardItemStyling = makeStyles((theme) => ({
  cardItemContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%"
  },
  cardToggled: {
    margin: "1em",
  },
  cardUntoggled: {
    display: "flex",
    marginTop: "1em",
    marginBottom: "0.5em",
    [theme.breakpoints.down("xl")]: {
      flexDirection: "column",
    },
    "@media (max-width: 1920px)": {
      minWidth: "300px"
    },
    [theme.breakpoints.down("md")]: {
      minWidth: "100%",
      flexDirection: "column"
    },
  },
  redCard: {
    backgroundColor: "#FF4D4D",
    color: "#fff",
    textAlign: "center",
  },
  amberCard: {
    backgroundColor: "#F2C800",
    color: "#fff",
    textAlign: "center",
  },
  greenCard: {
    backgroundColor: "#4BD28F",
    color: "#fff",
    textAlign: "center",
  },
  cardContentToggled: {
    [theme.breakpoints.down("xl")]: {
      marginLeft: "6em",
      marginRight: "6em",
      marginTop: "6em",
      marginBottom: "6em",
      minWidth: "400px",
    },
    "@media (max-width: 1920px)": {
      margin: "2em",
      minWidth: "350px",
    },
    [theme.breakpoints.down("md")]: {
      margin: "0.5em",
      flexDirection: "column"
    },
  },
  cardContentUntoggled: {
    [theme.breakpoints.up("xl")]: {
      margin: "3em",
      marginLeft: "5em",
      marginRight: "5em"
    },
    "@media (max-width: 1920px)": {
      margin: "0.5em",
      paddingBottom: "12px !important",
    },
  },
  viewToggled: {
    marginTop: "4em",
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  viewUntoggled: {
    marginTop: "2em"
  },
  plcSuccessToggled: {
    [theme.breakpoints.down("xl")]: {
      color: "#4CAF50",
      fontSize: "25rem"
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "20rem"
    },
  },
  plcSuccessUnToggled: {
    width: "100%",
    [theme.breakpoints.down("xl")]: {
      color: "#4CAF50",
      fontSize: "15rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "7.5rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "5rem"
    },
  },
  plcWarningToggled: {
    [theme.breakpoints.down("xl")]: {
      color: "#A9A9A9",
      fontSize: "25rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20rem"
    },
  },
  plcWarningUnToggled: {
    [theme.breakpoints.down("xl")]: {
      // color: "#FF4D4D",
      color: "#A9A9A9",
      fontSize: "15rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem"
    },
  },
  plcErrorToggled: {
    [theme.breakpoints.down("xl")]: {
      color: "#FF4D4D",
      fontSize: "25rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20rem"
    },
  },
  plcErrorUnToggled: {
    [theme.breakpoints.down("xl")]: {
      color: "#FF4D4D",
      fontSize: "15rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem"
    },
  },
}));
