import { Box, Typography, Modal, Button, Link } from "@material-ui/core";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const { REACT_APP_SUPPORT_EMAIL } = process.env;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  errorModal: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #FF4D4D",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  successModal: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #4BD28F",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  amberModal: {
    position: "absolute",
    minWidth: "30%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #F2C800",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  modalTextDistance: {
    marginTop: "1em",
    marginBottom: "1em",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
}

export const MonitoringModal = ({
  data,
  handleOpen,
  handleClose,
  open,
  trafficLightSystem,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        style={modalStyle}
        className={
          data.level === "ERROR" ||
          data.level === "Error" ||
          data._line?.includes("Error") ||
          data._line?.includes("error") ||
          data.timeDifference > trafficLightSystem.day
            ? classes.errorModal
            : data.timeDifference < trafficLightSystem.hour
            ? classes.successModal
            : classes.amberModal
        }
      >
        <Box padding={"20px"} textAlign="center">
          <Typography variant="h3" className={classes.modalTextDistance}>
            {data.level === "ERROR" ||
            data.level === "Error" ||
            data._line?.includes("Error") ||
            data._line?.includes("error") ||
            data.timeDifference > trafficLightSystem.day
              ? "Red"
              : data.timeDifference < trafficLightSystem.hour
              ? "Green"
              : "Amber"}
          </Typography>
          <Typography
            variant="h5"
            style={{ fontWeight: "600" }}
            className={classes.modalTextDistance}
          >
            {data.message ? data.message : data._line};
          </Typography>
          <Typography variant="h5" className={classes.modalTextDistance}>
            {data.formattedTime} - {data.timeFromLastGeneratedLabel}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {data.timeDifference > trafficLightSystem.hour && (
            // will open the default email app on the users system
            <Link
              href={`mailto:${REACT_APP_SUPPORT_EMAIL}?subject=Monitoring%20Query&body=Enter%20your%20query`}
            >
              <Button
                variant="contained"
                color="error"
                style={{ marginRight: "1em" }}
              >
                Email support
              </Button>
            </Link>
          )}
          <Button onClick={handleClose} variant="contained" color="primary">
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
