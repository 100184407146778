import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DatePicker from '@mui/lab/DatePicker';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchGraphData,
  setTimeFrame,
  setTotalOrSelective,
  updateStartDate,
  updateEndDate,
} from '../../../store/reducers/slices/graphDataSlice';
import {
  setSelectChartDataRange,
  setSelectedTaskType,
  setSelectedTaskName,
  getTaskData,
  getAllTaskTypeCounts, // important to get values with getTaskData
  setDateReset,
  resetCounts,
} from '../../../store/reducers/slices/dashboardDataSlice';
import TaskTypeSelectList from '../../global/TaskTypeSelectList';

const DateHandler = (props) => {
  const dispatch = useDispatch();
  const { startDate, endDate, timeFrame } = useSelector(
    (state) => state.manageGraphData
  );

  const { selectedTaskType, taskTypesWithCounts, selectChartDataRange } =
    useSelector((state) => state.manageDashboardData);

  const [taskTypeSelectList, setTaskTypeSelectList] = useState([]);

  useEffect(() => {
    getTaskData({ startDate: startDate, endDate: endDate });
  }, [endDate, startDate]);

  const populateSelectList = useCallback(() => {
    const tempCountForEachType = [];
    const tempTaskTypes = [...taskTypesWithCounts];
    const tempTotal = {
      task_type_id: 0,
      task_type_name: 'All tasks',
    };

    tempTaskTypes.unshift(tempTotal);

    tempTaskTypes?.forEach((taskType) => {
      tempCountForEachType.push({
        ...taskType,
        totalCount:
          taskType.task_type_id === 0
            ? taskType.processedTotal
            : taskType.processed,
      });
    });
    setTaskTypeSelectList(tempCountForEachType);
  }, [taskTypesWithCounts]);

  useEffect(() => {
    if (taskTypesWithCounts?.length > 0) {
      setTaskTypeSelectList(taskTypesWithCounts);

      populateSelectList();
    }
  }, [populateSelectList, taskTypesWithCounts]);

  const handleStartDateOnChange = (e) => {
    dispatch(setDateReset(false));
    dispatch(updateStartDate(e));
    dispatch(
      fetchGraphData({
        id: selectedTaskType,
        timeFrame: timeFrame,
        isTotalAmount: 'selective',
        startDate: e,
        endDate: endDate,
      })
    );
    dispatch(setTotalOrSelective('selective'));
    dispatch(getTaskData({ startDate: e, endDate: endDate }));
    dispatch(getAllTaskTypeCounts({ startDate: e, endDate: endDate }));
  };

  const handleEndDateOnChange = (e) => {
    dispatch(setDateReset(false));
    let endDate = e;
    dispatch(updateEndDate(endDate));
    dispatch(
      fetchGraphData({
        id: selectedTaskType,
        timeFrame: timeFrame,
        isTotalAmount: 'selective',
        startDate: startDate,
        endDate: endDate,
      })
    );
    dispatch(setTotalOrSelective('selective'));
    dispatch(getTaskData({ startDate: startDate, endDate: e }));
    dispatch(getAllTaskTypeCounts({ startDate: startDate, endDate: e }));
  };

  const handleResetOnClick = () => {
    dispatch(setDateReset(true));
    const resetDate = new Date();
    const tempStartDate = new Date(
      resetDate.getFullYear(),
      resetDate.getMonth(),
      resetDate.getDate() - 30
    );
    const tempEndDate = new Date(
      resetDate.getFullYear(),
      resetDate.getMonth(),
      resetDate.getDate()
    );
    dispatch(updateStartDate(tempStartDate));
    dispatch(updateEndDate(tempEndDate));
    dispatch(setTimeFrame('Hour'));
    dispatch(setTotalOrSelective('selective'));
    dispatch(
      fetchGraphData({
        id: 0,
        timeFrame: 'Hour',
        isTotalAmount: 'selective',
        startDate: tempStartDate,
        endDate: tempEndDate,
      })
    );
    dispatch(setSelectChartDataRange(1));
    dispatch(setSelectedTaskType(0));
    dispatch(getTaskData({ startDate: tempStartDate, endDate: tempEndDate }));
    dispatch(resetCounts());
    dispatch(
      getAllTaskTypeCounts({ startDate: tempStartDate, endDate: tempEndDate })
    );
  };

  const handleTaskType = async (e) => {
    var tempStart = new Date(startDate).getTime();
    var tempEnd = new Date(endDate).getTime();

    if (e.target.value === 0) {
      dispatch(
        getAllTaskTypeCounts({ startDate: startDate, endDate: endDate })
      );
    }

    taskTypeSelectList.forEach((item) => {
      if (item.task_type_id === e.target.value) {
        dispatch(setSelectedTaskName(item.task_type_name));
        dispatch(setSelectedTaskType(e.target.value));
      }
    });
    if (tempStart === tempEnd) {
      if (selectChartDataRange === 1) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Hour',
            isTotalAmount: 'total',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Hour'));
        dispatch(setTotalOrSelective('total'));
      } else if (selectChartDataRange === 2) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Day',
            isTotalAmount: 'total',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Day'));
        dispatch(setTotalOrSelective('total'));
      } else if (selectChartDataRange === 3) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Week',
            isTotalAmount: 'total',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Week'));
        dispatch(setTotalOrSelective('total'));
      }
    }
    if (tempStart !== tempEnd) {
      if (selectChartDataRange === 1) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Hour',
            isTotalAmount: 'selective',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Hour'));
        dispatch(setTotalOrSelective('selective'));
      } else if (selectChartDataRange === 2) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Day',
            isTotalAmount: 'selective',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Day'));
        dispatch(setTotalOrSelective('selective'));
        dispatch(setTimeFrame('Day'));
        dispatch(setTotalOrSelective('selective'));
      } else if (selectChartDataRange === 3) {
        dispatch(
          fetchGraphData({
            id: e.target.value,
            timeFrame: 'Week',
            isTotalAmount: 'selective',
            startDate: startDate,
            endDate: endDate,
          })
        );
        dispatch(setTimeFrame('Week'));
        dispatch(setTotalOrSelective('selective'));
      }
    }
  };

  return (
    <>
      <Accordion
        defaultExpanded
        style={{ marginY: '2em', boxShadow: 'none', width: '100%' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Typography align='center' variant='h6'>
            Manage graph
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display='flex' justifyContent='center'>
            <Box
              display='flex'
              flexDirection='column'
              style={{
                width: '100%',
                backgroundColor: '#FFFFFF',
              }}
            >
              <Box>
                <Box
                  display='flex'
                  alignItems='center'
                  alignContent='center'
                  justifyContent='center'
                >
                  <Box marginRight='2em'>
                    <TaskTypeSelectList
                      taskTypesSelect={taskTypeSelectList}
                      handleTaskType={handleTaskType}
                      selectedTaskType={selectedTaskType}
                    />
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                    marginRight='2em'
                  >
                    <FormControl>
                      <InputLabel id='select-label'>By</InputLabel>
                      <Select
                        labelId='select items'
                        id={`${props.selectChartDataRange}item`}
                        value={props.selectChartDataRange}
                        label='By timeframe'
                        onChange={props.handleChange}
                      >
                        <MenuItem value={1}>Hour</MenuItem>
                        <MenuItem value={2}>Day</MenuItem>
                        <MenuItem value={3}>Week</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignContent='center'
                    alignItems='center'
                  >
                    <Box>
                      <DatePicker
                        formatDate
                        label='from'
                        value={startDate}
                        maxDate={endDate}
                        minDate={new Date('01-01-2020')}
                        onChange={(newValue) => {
                          handleStartDateOnChange(newValue);
                        }}
                        inputFormat='dd/MM/yyyy'
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                    <Box marginX={'2em'}>
                      <DatePicker
                        label='to'
                        value={endDate}
                        minDate={startDate}
                        onChange={(newValue) => {
                          handleEndDateOnChange(newValue);
                        }}
                        inputFormat='dd/MM/yyyy'
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={(e) => handleResetOnClick(e)}
                      style={{ marginLeft: '10px', marginTop: '10px' }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DateHandler;
