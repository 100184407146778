import React, { PureComponent } from 'react';
import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { connect } from 'react-redux';
import {
  fetchGraphData,
  setTimeFrame,
  setTotalOrSelective,
} from '../../../store/reducers/slices/graphDataSlice';
import {
  setSelectChartDataRange,
  setSelectedTaskType,
  getTaskData,
} from '../../../store/reducers/slices/dashboardDataSlice';
import DateHandler from '../DateHandler/DateHandler';
import LoadingSpinner from '../../global/LoadingSpinner';
import { Box } from '@material-ui/core';
import { Card } from '@material-ui/core';

function mapStateToProps(state, ownProps) {
  const { manageGraphData, manageDashboardData } = state;
  const { data, startDate, endDate, graphLoading } = manageGraphData;
  const {
    selectChartDataRange,
    selectedTaskType,
    processedCountTotal,
    countForEachTaskType,
    taskTypesWithCounts,
    dateReset,
  } = manageDashboardData;

  return {
    graphLoading,
    data,
    selectChartDataRange,
    selectedTaskType,
    processedCountTotal,
    countForEachTaskType,
    taskTypesWithCounts,
    startDate,
    endDate,
    dateReset,
  };
}

class DataChart extends PureComponent {
  constructor() {
    super();

    this.state = {
      taskName: '',
      taskTypesSelect: [],
    };
  }

  handleChange = (e) => {
    if (e.target.value) {
      let tempStart = new Date(this.props.startDate).getTime();
      let tempEnd = new Date(this.props.endDate).getTime();
      switch (e.target.value) {
        case 1:
          this.props.setSelectChartDataRange(e.target.value);
          this.props.dispatch(setSelectChartDataRange(e.target.value));
          if (tempStart !== tempEnd) {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Hour',
                isTotalAmount: 'selective',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Hour'));
            this.props.dispatch(setTotalOrSelective('selective'));
          } else {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Hour',
                isTotalAmount: 'total',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Hour'));
            this.props.dispatch(setTotalOrSelective('total'));
          }

          break;
        case 2:
          this.props.setSelectChartDataRange(e.target.value);
          this.props.dispatch(setSelectChartDataRange(e.target.value));
          if (tempStart !== tempEnd) {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Day',
                isTotalAmount: 'selective',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Day'));
            this.props.dispatch(setTotalOrSelective('selective'));
          } else {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Day',
                isTotalAmount: 'total',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Day'));
            this.props.dispatch(setTotalOrSelective('total'));
          }

          break;
        case 3:
          this.props.setSelectChartDataRange(e.target.value);
          this.props.dispatch(setSelectChartDataRange(e.target.value));
          if (tempStart !== tempEnd) {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Week',
                isTotalAmount: 'selective',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Week'));
            this.props.dispatch(setTotalOrSelective('selective'));
          } else {
            this.props.dispatch(
              fetchGraphData({
                id: this.props.selectedTaskType,
                timeFrame: 'Week',
                isTotalAmount: 'total',
                startDate: this.props.startDate,
                endDate: this.props.endDate,
              })
            );
            this.props.dispatch(setTimeFrame('Week'));
            this.props.dispatch(setTotalOrSelective('total'));
          }
          break;
        default:
          console.log('how?');
      }
    }
  };
  render() {
    return (
      <>
        <DateHandler
          selectChartDataRange={this.props.selectChartDataRange}
          handleChange={this.handleChange}
        />
        <Card
          variant='outlined'
          display='flex'
          flexDirection='column'
          style={{
            width: '100%',
            height: '100%',
            padding: '1em',
          }}
        >
          <ResponsiveContainer width='100%' height='100%'>
            {this.props.graphLoading ? (
              <Box
                display='flex'
                alignContent='center'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
              >
                <LoadingSpinner />
              </Box>
            ) : (
              <LineChart data={this.props.data} syncId='anyId'>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey={
                    this.props.selectChartDataRange === 1
                      ? 'OnHour'
                      : this.props.selectChartDataRange === 2
                      ? 'OnDay'
                      : this.props.selectChartDataRange === 3
                      ? 'OnWeek'
                      : 'OnHour'
                  }
                ></XAxis>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  dataKey={
                    this.props.selectChartDataRange === 1
                      ? 'Hour'
                      : this.props.selectChartDataRange === 2
                      ? 'Day'
                      : this.props.selectChartDataRange === 3
                      ? 'Week'
                      : 'Hour'
                  }
                  stroke='#005170'
                  fill='#005170'
                  barSize={80}
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </Card>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchGraphData,
  setTimeFrame,
  setTotalOrSelective,
  setSelectChartDataRange,
  setSelectedTaskType,
  getTaskData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DataChart);
