import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { MonitoringItem } from './monitoringItem';
import { useEffect } from 'react';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { useState } from 'react';
import { SearchedIdModal } from './modals/searchedIdModal';
import MonitoringContainer from '../Containers/MonitoringContainer';
import { monitoringStyling } from '../../styles/MonitoringStyling';
import _ from 'lodash';

export const KeymasMonitoringPageSection = ({
  data,
  plcData,
  header,
  lastGeneratedLabel,
  lastGeneratedHeader,
  requireDirectoryCheck,
  status,
  toggleFullWidthBoxes,
  setToggleFullWidthBoxes,
  directoryLoading,
  dataLoading,
  operationTimes,
  plcLoading,
  processedCountTotal,
  processingCountTotal,
  errorCountTotal,
  taskDataLoading,
  loading,
}) => {
  const classes = monitoringStyling();

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  // const [localLoading, setLocalLoading] = useState(true);
  const handleOpen = (log) => {
    setModalData(log);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setModalData({});
  };

  useEffect(() => {
    if (data?.length > 0) {
      // Logic if loading will be used in the future
      // setLocalLoading(false);
    } else if (data?.length === 0) {
      setTimeout(() => {
        // Logic if loading will be used in the future
        // setLocalLoading(false);
      }, 5000);
    }
  }, [data?.length, data]);

  return (
    <>
      <Grid
        container
        item
        xs={toggleFullWidthBoxes.toggled ? 12 : 4}
        className={
          toggleFullWidthBoxes.toggled
            ? classes.gridContainerToggled
            : toggleFullWidthBoxes.hidden
            ? classes.gridContainerHide
            : classes.gridContainer
        }
      >
        <MonitoringContainer toggleFullWidthBoxes={toggleFullWidthBoxes}>
          <Box
            display='flex'
            justifyContent={
              requireDirectoryCheck ? 'space-between' : 'flex-end'
            }
          >
            {requireDirectoryCheck && (
              <Box display='flex'>
                {status ? (
                  <Tooltip title={`Folder access online - ${status.files}`}>
                    <status-indicator
                      positive
                      pulse
                      className={
                        toggleFullWidthBoxes.toggled &&
                        classes.statusIndicatorToggled
                      }
                    ></status-indicator>
                  </Tooltip>
                ) : directoryLoading ? (
                  <Tooltip title='Folder access pending...'>
                    <status-indicator
                      warning
                      pulse
                      className={
                        toggleFullWidthBoxes.toggled &&
                        classes.statusIndicatorToggled
                      }
                    ></status-indicator>
                  </Tooltip>
                ) : (
                  <Tooltip title='Folder access offline - unknown'>
                    <status-indicator
                      negative
                      pulse
                      className={
                        toggleFullWidthBoxes.toggled &&
                        classes.statusIndicatorToggled
                      }
                    ></status-indicator>
                  </Tooltip>
                )}
              </Box>
            )}
            <Box height='38px' display='flex' justifyContent='flex-end'>
              <Button
                onClick={() => setToggleFullWidthBoxes(toggleFullWidthBoxes)}
              >
                {toggleFullWidthBoxes.toggled ? (
                  <FullscreenExitIcon fontSize='large' />
                ) : (
                  <FullscreenIcon />
                )}
              </Button>
            </Box>
          </Box>
          <Typography
            className={
              toggleFullWidthBoxes.toggled
                ? classes.header
                : classes.headerUnToggled
            }
          >
            {header}
          </Typography>
          <>
            <Box
              className={
                toggleFullWidthBoxes.toggled
                  ? classes.checkCodeSearchToggled
                  : classes.checkCodeSearchUnToggled
              }
            >
              {lastGeneratedLabel && (
                <Box
                  className={
                    toggleFullWidthBoxes.toggled
                      ? classes.lastGeneratedHeaderContainer
                      : classes.lastGeneratedHeaderContainerUntoggled
                  }
                >
                  <Typography
                    variant={toggleFullWidthBoxes.toggled ? 'h4' : 'h5'}
                    classes={
                      toggleFullWidthBoxes.toggled
                        ? classes.lastGeneratedHeader
                        : ''
                    }
                  >
                    {lastGeneratedHeader}
                  </Typography>
                  <Card
                    variant='outlined'
                    className={classes.lastGeneratedItem}
                  >
                    <Box
                      padding={toggleFullWidthBoxes.toggled ? '20px' : '10px'}
                      display='flex'
                      justifyContent='center'
                    >
                      <Typography
                        variant={toggleFullWidthBoxes.toggled ? 'h4' : 'h6'}
                      >
                        {lastGeneratedLabel
                          ? lastGeneratedLabel
                          : 'None available.'}
                      </Typography>
                    </Box>
                  </Card>
                </Box>
              )}
              <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignItems='center'
                alignContent='center'
                textAlign='center'
              >
                {dataLoading &&
                !operationTimes.offline &&
                (data?.length === 0 ||
                  _.isEmpty(plcData) ||
                  processedCountTotal === 0) ? (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignContent='center'
                    alignItems='center'
                    height='200px'
                  >
                    <CircularProgress />
                  </Box>
                ) : !dataLoading &&
                  !operationTimes.offline &&
                  (data?.length > 0 ||
                    !_.isEmpty(plcData) ||
                    processedCountTotal > 0) ? (
                  <MonitoringItem
                    data={data}
                    plcData={plcData}
                    toggleFullWidthBoxes={toggleFullWidthBoxes}
                    cardContentClass={classes.cardContent}
                    plcLoading={plcLoading}
                    processedCountTotal={processedCountTotal}
                    processingCountTotal={processingCountTotal}
                    errorCountTotal={errorCountTotal}
                  />
                ) : !dataLoading &&
                  !operationTimes.offline &&
                  (data?.length === 0 ||
                    _.isEmpty(plcData) ||
                    processedCountTotal === 0) ? (
                  <Box marginY='2em'>
                    <Typography
                      variant={toggleFullWidthBoxes.toggled ? 'h3' : 'h5'}
                    >
                      No logs to display.
                    </Typography>
                  </Box>
                ) : (
                  operationTimes.offline && (
                    <Typography>Currently offline.</Typography>
                  )
                )}
              </Box>
            </Box>
          </>
        </MonitoringContainer>
      </Grid>
      <SearchedIdModal
        data={modalData}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  );
};
