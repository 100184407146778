export const GET_MONITORING_DATA = 'GET_MONITORING_DATA';
export const CHECK_DESPATCH_NOTE_GENERATED = 'CHECK_DESPATCH_NOTE_GENERATED';
export const GET_DESPATCH_LAST_GENERATED_LABEL = 'GET_DESPATCH_LAST_GENERATED_LABEL';
export const GET_DESPATCH_NOTES_LOGS = 'GET_DESPATCH_NOTES_LOGS';
export const GET_UPS_GENERATOR_LOGS = 'GET_UPS_GENERATOR_LOGS';
export const GET_LOG_UPS_AND_DESPATCH_DATA = 'GET_LOG_DATA';
export const GET_LOG_UPS_INFO = 'GET_LOG_UPS_INFO';
export const GET_LOG_UPS_ERROR = 'GET_LOG_UPS_ERROR';
export const GET_LOG_DESPATCH_INFO = 'GET_LOG_DESPATCH_INFO';
export const GET_LOG_DESPATCH_ERROR = 'GET_LOG_DESPATCH_ERROR'
export const GET_LOG_KEYMAS_INFO_DATA = 'GET_LOG_KEYMAS_INFO_DATA';
export const GET_LOG_KEYMAS_ERROR_DATA = 'GET_LOG_KEYMAS_ERROR_DATA';
export const GET_LAST_LABEL_GENERATED_DATE_UPS = 'GET_LAST_LABEL_GENERATED_DATE_UPS';
export const CHECK_CARTON_BARCODE_HAS_LABEL_GENERATED_UPS = 'CHECK_CARTON_BARCODE_HAS_LABEL_GENERATED_UPS';
export const GET_TYPE_DATA_FROM_SEARCH_TERM = 'GET_TYPE_DATA_FROM_SEARCH_TERM';
export const GET_ALL_TASK_DATA = 'GET_ALL_TASK_DATA';
export const SEARCH_TASK_TYPES = 'SEARCH_TASK_TYPES';
export const ERROR_TABLE_DATA = 'ERROR_TABLE_DATA';
export const PROCESSED_TABLE_DATA = 'PROCESSED_TABLE_DATA';
export const GET_PROCESSED_TABLE_DATA = 'GET_PROCESSED_TABLE_DATA';
export const GET_PROCESSED_TABLE_DATA_FULL = 'GET_PROCESSED_TABLE_DATA_FULL';
export const GET_PROCESSING_TABLE_DATA = 'GET_PROCESSING_TABLE_DATA';
export const GET_PROCESSING_TABLE_DATA_FULL = 'GET_PROCESSING_TABLE_DATA_FULL';
export const GET_ERRORED_TABLE_DATA = 'GET_ERRORED_TABLE_DATA';
export const GET_ERRORED_TABLE_DATA_FULL = 'GET_ERRORED_TABLE_DATA_FULL';
export const GET_ERRORED_QUEUE_DATA = 'GET_ERRORED_QUEUE_DATA';
export const GET_COUNT_FOR_EACH_TYPE = 'GET_COUNT_FOR_EACH_TYPE';
export const GET_TASK_TYPES = 'GET_TASK_TYPES';
export const GET_LAST_UPDATED = 'GET_LAST_UPDATED';
export const GET_COUNT_TASKS = 'GET_COUNT_TASKS';
export const GET_ALL_COUNT_TASKS = 'GET_ALL_COUNT_TASKS';
export const COUNT_TASKS = 'COUNT_TASKS';
export const TASK_DATA = 'TASK_DATA';
export const MANAGE_DATES = 'MANAGE_DATES';
export const GET_GRAPH_DATA = 'GET_GRAPH_DATA';
export const TASK_DATA_ERROR = 'TASK_DATA_ERROR';
export const CONF_SET_ALERT = 'CONF_SET_ALERT';
export const GET_CONF_TASK_TYPES = 'GET_CONF_TASK_TYPES';
export const ADD_CONF_TASK_TYPE = 'ADD_CONF_TASK_TYPE';
export const EDIT_CONF_TASK_TYPE = 'EDIT_CONF_TASK_TYPE';
export const DELETE_CONF_TASK_TYPE = 'DELETE_CONF_TASK_TYPE';
export const ADD_CONF_CRON = 'ADD_CONF_CRON';
export const EDIT_CONF_CRON = 'EDIT_CONF_CRON';
export const DELETE_CONF_CRON = 'DELETE_CONF_CRON';
export const POST_CONF_MANAGE = 'POST_CONF_MANAGE';
export const GET_CONF_DATA = 'GET_CONF_DATA';
export const GET_CONF_SCHEDULE = 'GET_CONF_SCHEDULE';
export const CHECK_IF_LABEL_FOR_REGENERATION_IS_PREPACK = 'CHECK_IF_LABEL_FOR_REGENERATION_IS_PREPACK';
export const REGENERATE_LABEL = 'REGENERATE_LABEL';
export const RESENDTASKQUEUEITEM = 'RESEND_TASK_QUEUE_ITEM';