import React from "react";
import { Paper, Container } from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";

const StyledPaper = withTheme(styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)}px;
  width: ${(props) => (props.$fullWidth ? "100vw" : null)};
`);

const StyledMonitoringPaper = withTheme(styled(Paper)`
  padding: ${(props) => props.theme.spacing(2)}px;
  width: ${(props) => (props.$fullWidth ? "100vw" : null)};
  height: ${(props) => (props.style = "100%")};
`);

// {height: "calc(100vh / 2)"}

const PaperContainer = (props) => {
  return (
    <Container
      maxWidth={props.toggleFullWidthBoxes?.toggled ? false : "xl"}
      style={
        props.toggleFullWidthBoxes?.toggled
          ? { maxWidth: "90%", maxHeight: "95%" }
          : { height: "100%" }
      }
    >
      {props?.toggleFullWidthBoxes ? (
        <StyledMonitoringPaper>{props.children}</StyledMonitoringPaper>
      ) : (
        <StyledPaper>{props.children}</StyledPaper>
      )}
    </Container>
  );
};

export default PaperContainer;
