import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const Header = ({ pageName }) => {
  const { lastUpdated } = useSelector((state) => state.manageDashboardData);

  return (
    <Box style={{ marginBottom: "1em" }}>
      <Typography variant="h2" style={{ textAlign: "center" }}>
        {pageName}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginY="1em"
      >
        <Typography variant="h4">Last Updated :</Typography>
        <Typography variant="h4" style={{ color: "black", marginLeft: "10px" }}>
          {lastUpdated
            ? `${new Date(lastUpdated).toLocaleDateString("en-UK")}`
            : ""}{" "}
          {lastUpdated ? `${new Date(lastUpdated).toLocaleTimeString()}` : ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
