import { Box, InputLabel, Select, MenuItem } from "@material-ui/core"

const TaskTypeSelectList = ({taskTypesSelect, handleTaskType, selectedTaskType, color}) => {

    return (
        <Box>
        <InputLabel shrink={true}>Task types</InputLabel>
        <Select
          labelId="task-process-id"
          id="task-process"
          key={selectedTaskType}
          value={selectedTaskType}
          label="task process"
          onChange={handleTaskType}
        >
          {taskTypesSelect !== undefined
            ? taskTypesSelect?.map((item, i) => (
                <MenuItem key={i} value={item.task_type_id}>
                  {`${item.task_type_name} ${
                    item.totalCount ? `[${item.totalCount}]` : ""
                  }`}
                </MenuItem>
              ))
            : ""}
        </Select>
      </Box>
    )
}

export default TaskTypeSelectList;