import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: '0e07b48d-b30f-4e02-b554-f23aad4c4b35',
    'Access-Control-Allow-Origin': '*',
    'x-jwt-token':
      'eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds',
  },
};

export const fetchQueueAddSearch = createAsyncThunk(
  'GET_QUEUE_ADD_SEARCH',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getQueueAddSearch`,
        {
          ...params,
          params: { SearchTerm: data.search, Task_Type_ID: data.taskType },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue('Something went wrong');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const checkIfQueued = createAsyncThunk(
  'CHECK_IF_QUEUED',
  async (data, { rejectWithValue }) => {
    try {
      let rowResults = [];
      for (const row_data of data.rows_data) {
        const response = await axios.get(
          `${REACT_APP_API_BASE_URI}keymas/dashboard/checkIfQueued`,
          {
            ...params,
            params: {
              task_type_id: data.task_type_id,
              task_pavfk_id: row_data.fkID,
            },
          }
        );
        if (response.status === 200) {
          if (response.data.response.recordset[0]?.total > 0) {
            rowResults.push({
              Record: row_data.Record,
              task_pavfk_id: row_data.fkID,
              to_add: false,
            });
          } else {
            rowResults.push({
              Record: row_data.Record,
              task_pavfk_id: row_data.fkID,
              to_add: true,
            });
          }
        } else {
          return rejectWithValue('Something went wrong');
        }
      }
      return rowResults;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

export const postQueueAddSearch = createAsyncThunk(
  'POST_QUEUE_ADD_SEARCH',
  async (data, { rejectWithValue, getState }) => {
    try {
      let queueToAdd = getState().manageQueueAddData.queueToAdd;
      let queued = [];
      for (const task of queueToAdd) {
        if (task.to_add === true) {
          let testData = {
            task_type_id: parseInt(data.taskType),
            task_pavfk_id: parseInt(task.task_pavfk_id),
          };
          const response = await axios.post(
            `${REACT_APP_API_BASE_URI}keymas/dashboard/postQueueAddSearch`,
            testData,
            params
          );

          if (response.status === 200) {
            queued.push(task.Record);
          } else {
            return rejectWithValue('Something went wrong');
          }
        }
      }
      return queued;
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

const addTaskQueueSlice = createSlice({
  name: 'QUEUE_ADD',
  initialState: {
    searchQueueData: [],
    checkIfQueuedData: false,
    queueToAdd: [],
    queueToastData: { success: [], failed: [] },
    alertData: { open: false, severity: 'success', message: '' },
    queued: false,
  },
  reducers: {
    resetSearchQueueData(state) {
      state.searchQueueData = [];
    },
    addAlert(state, action) {
      state.alertData = action.payload;
    },
    resetAlert(state) {
      state.alertData = { open: false, severity: '', message: '' };
    },
    itemsQueuedAlert(state, action) {
      state.queueToastData.failed = [
        ...state.queueToastData.failed,
        action.Record,
      ];
    },
    displayItemsQueueAlert(state, action) {
      let message = [];
      state.queueToastData.success.forEach((name, iteration) => {
        if (iteration === 0) {
          message = [...message, 'Items Added: ', name];
        } else {
          message = [...message, name];
        }
      });

      state.queueToastData.failed.forEach((name, iteration) => {
        if (iteration === 0) {
          message = [...message, 'Already Queued: ', name];
        } else {
          message = [...message, name];
        }
      });

      state.queueToastData = { success: [], failed: [] };

      state.alertData = {
        open: true,
        severity: 'success',
        message: message,
      };
    },
    default(state) {
      return state;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQueueAddSearch.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.searchQueueData = [{ Record: 'N/A', fkID: 'N/A' }];
        state.alertData = {
          open: true,
          severity: 'warning',
          message: 'No data for search query',
        };
      } else {
        state.searchQueueData = action.payload;
      }
    });

    builder.addCase(checkIfQueued.fulfilled, (state, action) => {
      state.queueToAdd = [...action.payload];
      action.payload.forEach((item) => {
        if (item.to_add === false) {
          state.queueToastData.failed = [
            ...state.queueToastData.failed,
            item.Record,
          ];
        }
      });
    });

    builder.addCase(checkIfQueued.rejected, (state) => {
      state.alertData = {
        open: true,
        severity: 'error',
        message: 'Could not check if queued.',
      };
    });

    builder.addCase(fetchQueueAddSearch.rejected, (state) => {
      state.searchQueueData = [{ Record: 'N/A', fkID: 'N/A' }];
      state.alertData = {
        open: true,
        severity: 'error',
        message: 'Could not get search data',
      };
    });
    builder.addCase(postQueueAddSearch.fulfilled, (state, action) => {
      state.queueToastData.success = [...action.payload];
    });

    builder.addCase(postQueueAddSearch.rejected, (state, action) => {
      state.alertData = {
        open: true,
        severity: 'error',
        message: 'Could not add data to queue',
      };
    });
  },
});

export const {
  resetSearchQueueData,
  resetAlert,
  addAlert,
  itemsQueuedAlert,
  displayItemsQueueAlert,
} = addTaskQueueSlice.actions;
export default addTaskQueueSlice.reducer;
