import React from "react";
import { Paper, Container } from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";

const StyledMonitoringPaper = withTheme(styled(Paper)`
  padding: 0.5em;
  width: ${(props) => (props.$fullWidth ? "100vw" : null)};
  height: ${(props) => (props.style = "100%")};
  overflow: hidden;
`);

// {height: "calc(100vh / 2)"} // nice little grid calc for full height

const MonitoringContainer = (props) => {
  return (
    <Container
      maxWidth={props.toggleFullWidthBoxes?.toggled ? false : "xl"}
      style={
        props.toggleFullWidthBoxes?.toggled
          ? { maxWidth: "90%", maxHeight: "95%" }
          : { padding: 0 }
      }
    >
      <StyledMonitoringPaper>{props.children}</StyledMonitoringPaper>
    </Container>
  );
};

export default MonitoringContainer;
