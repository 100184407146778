import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  COUNT_TASKS,
  GET_TASK_TYPES,
  GET_PROCESSING_TABLE_DATA_FULL,
} from "../../types";
import axios from "axios";
import format from "date-fns/format";

const DATE_FORMAT = 'yyyy-MM-dd';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

export const fetchAllTaskTypes = createAsyncThunk(
  GET_TASK_TYPES,
  async (data) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskTypes`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      }

      return response.data.types;
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchProcessingDataInFull = createAsyncThunk(
  GET_PROCESSING_TABLE_DATA_FULL,
  async (dataParams, { rejectWithValue }) => {
    const { type_id, start_date, end_date } = dataParams;
    const tempStartDate = format(start_date, DATE_FORMAT);
    const adjustEndDate = new Date(end_date);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);
    const tempEndDate = format(end_date, DATE_FORMAT);
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getProcessingTasksInFull?type_id=${type_id}&start_date=${tempStartDate}&end_date=${tempEndDate}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }
      return response.data.processingTasks;
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

const processingTableSlice = createSlice({
  name: COUNT_TASKS,
  initialState: {
    data: [],
    testData: [],
    processedTotal: "",
    processedCount: "",
    processedForEachType: [],
    processingCountTotal: "",
    processingCount: "",
    processingForEachType: [],
    errorCountTotal: "",
    errorCount: "",
    errorForEachType: [],
    lastUpdated: new Date(),
    taskTypesRdx: [],
    loading: false,
    selectedTaskName: "",
    selectedTaskType: 0,
    loadingTableData: false,
    orderBy: "",
    paginationTaskTotal: 0,
    page: 0,
    rowsPerPage: 10,
  },
  reducers: {
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },

    setSelectedTaskType: (state, action) => {
      state.selectedTaskType = action.payload;
    },

    setSelectedTaskName: (state, action) => {
      state.selectedTaskName = action.payload;
    },

    setPaginationTaskTotal: (state, action) => {
      state.paginationTaskTotal = action.payload;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAllTaskTypes.fulfilled, (state, action) => {
      state.loadingTableData = false;
      state.taskTypesRdx = action.payload;
    });

    builder.addCase(fetchAllTaskTypes.pending, (state) => {
      state.loadingTableData = true;
    });

    builder.addCase(fetchAllTaskTypes.rejected, (state) => {
      state.loadingTableData = false;
    });

    builder.addCase(fetchProcessingDataInFull.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });

    builder.addCase(fetchProcessingDataInFull.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProcessingDataInFull.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setToastError,
  setToastWarning,
  setSelectedTaskType,
  setSelectedTaskName,
  setPaginationTaskTotal,
  setPage,
  setRowsPerPage,
  setSortDirection,
  setRequestSort,
  setQueueSort,
  setCompletedSort,
  setOrderBy,
} = processingTableSlice.actions;
export default processingTableSlice.reducer;
