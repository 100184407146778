import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function Toast({
  selectedToast,
  handleToastClose,
  alertType,
  message,
}) {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar
      open={selectedToast}
      autoHideDuration={5000}
      onClose={handleToastClose}
    >
      <Alert onClose={handleToastClose} severity={alertType}>
        {message}
      </Alert>
    </Snackbar>
  );
}
