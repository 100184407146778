import { makeStyles } from "@material-ui/core";

export const monitoringStyling = makeStyles((theme) => ({
  monitoringMainContainer: {
    padding: "10px",
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0,
  },
  header: {
    fontFamily: "Austin Roman, serif",
    fontWeight: "300",
    lineHeight: "0.85",
    letterSpacing: "0.018em",
    textAlign: "center",
    color: "#005170",
    [theme.breakpoints.down("xl")]: {
      fontSize: "6.428571428571429rem",
      // color: "red",
      textAlign: "center",
      marginTop: "0.75em",
      marginBottom: "1em",
    },
    "@media (max-width: 1920px)": {
      fontSize: "3.517857142857142rem",
      textAlign: "center",
      marginTop: "0.25em",
      marginBottom: "1.5em",
    },
  },
  headerUnToggled: {
    fontFamily: "Austin Roman, serif",
    fontWeight: "300",
    lineHeight: "0.85",
    letterSpacing: "0.018em",
    textAlign: "center",
    color: "#005170",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2.528571428571429rem",
      textAlign: "center",
    },
    "@media (max-width: 1920px)": {
      fontSize: "2.057857142857142rem",
      textAlign: "center",
      marginBottom: "0.5em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.5rem",
    },
  },
  checkCodeSearchUnToggled: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    marginTop: "2em",
    marginBottom: "2em",
    minHeight: "200px",
    "@media (max-width: 1920px)": {
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  checkCodeSearchToggled: {
    display: "flex",
    justifyContent: "space-evenly",
    alignContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "2em",
    marginBottom: "3em",
  },
  toggledDespIdLabel: {
    fontSize: "30px",
  },
  lastGeneratedHeaderContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2em",
    marginBottom: "2em",
    gap: "1em",
    [theme.breakpoints.down("xl")]: {
      marginTop: "6em",
      marginBottom: "6em",
    },
    "@media (max-width: 1920px)": {
      gap: "1em",
      marginTop: "0px",
      marginBottom: "2em",
    },
  },
  lastGeneratedHeaderContainerUntoggled: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: "0",
    gap: "0.5em",
  },
  lastGeneratedHeader: {
    [theme.breakpoints.down("xl")]: {
      marginTop: "1em",
      marginBottom: "2em",
    },
    "@media (max-width: 1920px)": {
      marginTop: "0.5em",
      marginBottom: "0.5em",
    },
  },
  gridContainerToggled: {
    position: "relative",
    height: "90vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    left: "0",
  },
  gridContainerHide: {
    display: "none",
  },
  statusIndicatorToggled: {
    width: "1.5em",
    height: "1.5em",
  },
  lastGeneratedItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  searchBarcodeContainer: {
    display: "flex",
    height: "90vh",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  barcodeGridItemHeight: { minHeight: "500px" },
  barcodeGridItemHeader: { textAlign: "center", marginTop: "0.25em" },
  doneIcon: {
    width: "100px",
    height: "100px",
    color: "#5cb85c",
  },
  errorIcon: {
    width: "100px",
    height: "100px",
    color: "#FF2020",
  },
  barcodeItemsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    width: "80%",
  },
}));
