import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import moment from "moment";

const SelectColumnFilterWithoutTimev2 = (props) => {
  const {
    column: { setFilter, preFilteredRows, id, filterValue },
  } = props;

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      let key = row.values[id].toString().split(" ").slice(0, 3).join(" ");
      if ([...options].filter((o) => o.key === key).length < 1) {
        options.add({
          key: key,
          value: moment(row.values[id]).format("DD/MM/YYYY"),
        });
      }
    });
    switch (id) {
      case "date":
        return [...options.values()].sort().reverse();
      default:
        return [...options.values()].sort();
    }
  }, [id, preFilteredRows]);

  return (
    <Select
      fullWidth
      onChange={(e) => {
        const { value } = e.target;
        setFilter(value === "select" ? undefined : value);
      }}
      value={filterValue || "select"}
    >
      <MenuItem value="select">Show all</MenuItem>
      {options.map((o) => (
        <MenuItem key={o.key} value={o.key}>
          {o.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectColumnFilterWithoutTimev2;
