import React from "react";
import { Box } from "@material-ui/core";
import DataChart from "./DataChart";
import { useSelector } from "react-redux";

const Charts = () => {
  const { startDate, endDate } = useSelector(
    (state) => state.manageGraphData
  );

  return (
      <Box className="graph-size">
        <DataChart startDate={startDate} endDate={endDate} />
      </Box>
  );
};

export default Charts;
