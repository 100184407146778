import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import CardItem from "./CardItem";

const CardContainer = () => {
  const {
    processedCount,
    processingCount,
    errorCount,
    processedCountTotal,
    processingCountTotal,
    errorCountTotal,
  } = useSelector((state) => state.manageDashboardData);

  return (
    <Box
      className="mobile-flex"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{ gap: "1em" }}
    >
      <CardItem
        header={"Tasks processed"}
        selectiveCount={processedCount}
        totalCount={processedCountTotal}
        linkUrl={"/processed-tasks-table-view"}
      />
      <CardItem
        header={"Tasks processing"}
        selectiveCount={processingCount}
        totalCount={processingCountTotal}
        linkUrl={"/processing-tasks-table-view"}
      />
      <CardItem
        header={"Task errors"}
        selectiveCount={errorCount}
        totalCount={errorCountTotal}
        linkUrl={"/errored-tasks-table-view"}
      />
    </Box>
  );
};

export default CardContainer;
