import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_GRAPH_DATA, TASK_DATA } from "../../types";
import axios from "axios";
import format from "date-fns/format";

const DATE_FORMAT = 'yyyy-MM-dd';
const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

const baseDate = new Date();

export const fetchGraphData = createAsyncThunk(
  GET_GRAPH_DATA,
  async (dataParams, { rejectWithValue }) => {
    const { id, timeFrame, isTotalAmount, startDate, endDate } = dataParams;
    const tempStartDate = format(startDate, DATE_FORMAT);
    const adjustEndDate = new Date(endDate);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);
    const tempEndDate = format(endDate, DATE_FORMAT);

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getAllGraphData?type_id=${id}&timeFrame=${timeFrame}&totalForTimeFrame=${isTotalAmount}&start_date=${tempStartDate}&end_date=${tempEndDate}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue("Unable to connect");
      }
      if (response.data.graphData.length > 0) {
        return response.data.graphData;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue("Unable to connect");
    }
  }
);

const graphDataSlice = createSlice({
  name: TASK_DATA,
  initialState: {
    loading: false,
    graphLoading: false,
    id: 0,
    data: [],
    totalOrSelective: "selective", // default - selective gets data between date range / total gets all data without date range.
    timeFrame: "Hour", // default
    startDate: new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() - 30
    ),
    endDate: new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate()
    ),
    isReset: false,
    toastError: false,
    toastWarning: false,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },

    setTotalOrSelective: (state, action) => {
      state.totalOrSelective = action.payload;
    },

    setTimeFrame: (state, action) => {
      state.timeFrame = action.payload;
    },

    updateStartDate: (state, action) => {
      if (state.startDate !== action.payload) {
        state.startDate = action.payload;
      }
    },

    updateEndDate: (state, action) => {
      if (state.endDate !== action.payload) {
        const updateEndDate = action.payload;
        state.endDate = updateEndDate;
      }
    },

    setTaskId: (state, action) => {
      state.id = action.payload;
    },

    setReset: (state, action) => {
      if (action.payload === true) {
        state.isReset = action.payload;
        state.startDate = new Date(
          baseDate.getFullYear(),
          baseDate.getMonth(),
          baseDate.getDate() - 30
        );
        state.endDate = new Date(
          baseDate.getFullYear(),
          baseDate.getMonth(),
          baseDate.getDate()
        );
        state.isReset = false;
      } else {
        state.isReset = action.payload;
      }
    },

    setGraphErrorToast: (state, action) => {
      state.toastError = action.payload;
    },

    setGraphWarningToast: (state, action) => {
      state.toastWarning = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGraphData.fulfilled, (state, action) => {
      state.graphLoading = false;
      if (action.payload !== undefined) {
        if (action.payload.length > 0) {
          state.data = action.payload;
          state.toastError = false;
          state.toastWarning = false;
        } else {
          state.data = [];
          state.toastWarning = true;
          state.toastError = false;
        }
      } else {
        state.toastError = true;
        state.toastWarning = false;
      }
    });

    builder.addCase(fetchGraphData.pending, (state) => {
      state.graphLoading = true;
    });

    builder.addCase(fetchGraphData.rejected, (state) => {
      state.graphLoading = false;
    });
  },
});

export const {
  setData,
  setTimeFrame,
  setTotalOrSelective,
  updateStartDate,
  updateEndDate,
  setReset,
  setGraphErrorToast,
  setGraphWarningToast,
} = graphDataSlice.actions;
export default graphDataSlice.reducer;
