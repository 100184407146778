import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import format from "date-fns/format";

const DATE_FORMAT = 'yyyy-MM-dd';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: "0e07b48d-b30f-4e02-b554-f23aad4c4b35",
    "Access-Control-Allow-Origin": "*",
    "x-jwt-token":
      "eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds",
  },
};

export const TaskDataApi = createApi({
  reducerPath: "taskDataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_BASE_URI,
  }),
  // keepUnusedDataFor: 5,
  tagTypes: ['All-Task-Data', 'All-Graph-Data'],
  endpoints: (builder) => ({
    getTaskData: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `keymas/dashboard/getTaskData?${startDate && endDate
          ? `start_date=${format(startDate, DATE_FORMAT)}&end_date=${format(endDate, DATE_FORMAT)}`
          : ""
          }`,
        providesTags: ['All-Task-Data'],
        headers: params.headers,
      }),
    }),
    getGraphData: builder.query({
      query: ({ id, timeFrame, isTotalAmount, startDate, endDate }) => ({
        url: `keymas/dashboard/getAllGraphData?type_id=${id}&timeFrame=${timeFrame}&totalForTimeFrame=${isTotalAmount}&start_date=${format(startDate, DATE_FORMAT)}&end_date=${format(endDate, DATE_FORMAT)}`,
        providesTags: ['All-Graph-Data'],
        headers: params.headers,
      }),
    }),
  }),
});

export const { useGetTaskDataQuery, useGetGraphDataQuery } = TaskDataApi;
