import graphDataReducer from "./reducers/slices/graphDataSlice";
import dashboardDataReducer from "./reducers/slices/dashboardDataSlice";
import processedTableReducer from "./reducers/slices/processedTableSlice";
import processingTableReducer from "./reducers/slices/processingTableSlice";
import errorTableReducer from "./reducers/slices/errorTableSlice";
import configSliceReducer from "./reducers/slices/configSlice";
import addTaskQueueSlice from "./reducers/slices/addTaskQueueSlice";
import searchSliceReducer from "./reducers/slices/searchSlice";
import monitoringSliceReducer from "./reducers/slices/monitoringSlice";

import { configureStore } from "@reduxjs/toolkit";

import { TaskDataApi } from "../services/TaskDataApi";

export const store = configureStore({
  reducer: {
    manageGraphData: graphDataReducer,
    manageDashboardData: dashboardDataReducer,
    manageProcessingTable: processingTableReducer,
    manageProcessedTable: processedTableReducer,
    manageErrorTable: errorTableReducer,
    manageConfigData: configSliceReducer,
    manageQueueAddData: addTaskQueueSlice,
    manageSearch: searchSliceReducer,
    manageMonitoringData: monitoringSliceReducer,

    // api (RTK Query)
    [TaskDataApi.reducerPath]: TaskDataApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(TaskDataApi.middleware),
});
