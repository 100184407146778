import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCheckDespatchPrintDirectory,
  fetchLogopakDirectoryStatus,
  fetchLogDnaUpsInfo,
  fetchLogDnaDespatchInfo,
  fetchLogDnaKeymasInfoLogs,
  fetchPLCStatus,
  fetchDespatchNoteLastCreatedLabel,
  getLastLabelGeneratedDate,
  getLastUpdateForDataToKeymas,
  setUpsLoading,
  setDespatchLoading,
  setDataToKeymasLogdnaLoading,
  setOperationTimesUps,
  setOperationTimesDespatch,
  setOperationTimesDataToKeymas,
  setOperationTimesDataFromKeymas,
} from '../store/reducers/slices/monitoringSlice';
import { KeymasMonitoringPageSection } from '../components/monitoring/keymasMonitoringPageSection';
import { monitoringStyling } from '../styles/MonitoringStyling';
import {
  getAllTaskTypeCounts, // features totals in date ranges.
} from '../store/reducers/slices/dashboardDataSlice';
import { baseDate } from '../components/helpers';

const {
  REACT_APP_DESPATCH_UPS_HOST,
  REACT_APP_DESPATCH_APP,
  REACT_APP_DESPATCH_QUERY,
  REACT_APP_UPS_APP,
  REACT_APP_KEYMAS_APP,
  REACT_APP_QUERY_DATA_TO_KEYMAS,
  REACT_APP_QUERY_DATA_FROM_KEYMAS,
} = process.env;

export const MonitoringView = ({
  setIsSelectedAnalytics,
  setIsSelectedSettings,
  setIsSelectedSearch,
  setIsSelectedMonitoring,
  setIsSelectedBarcodeSearch,
}) => {
  const dispatch = useDispatch();
  const classes = monitoringStyling();

  const {
    upsLabelGeneratorInfo,
    DespatchNoteGeneratorInfo,
    lastGeneratedLabelValueUps,
    keymasLogData,
    keymasPostLogData,
    despatchNoteLastGeneratedLabel,
    logopackStatus,
    despatchPrintDirectoryStatus,
    despatchDirectoryLoading,
    logopackLoading,
    lastItemToKeymas,
    operationTimesDespatch,
    operationTimesUps,
    operationTimesDataToKeymas,
    operationTImesDataFromKeymas,
    currentTime,
    dataToKeymasLogdnaLoading,
    plcStatus,
    plcLoading,
  } = useSelector((state) => state.manageMonitoringData);

  // counts for all task types within time frame.
  const {
    processedCountTotal,
    processingCountTotal,
    errorCountTotal,
    loading,
  } = useSelector((state) => state.manageDashboardData);

  const [toggleFullWidth, setToggleFullWidth] = useState([
    { toggled: false, hidden: false, id: 1 },
    { toggled: false, hidden: false, id: 2 },
    { toggled: false, hidden: false, id: 3 },
    { toggled: false, hidden: false, id: 4 },
    { toggled: false, hidden: false, id: 5 },
    { toggled: false, hidden: false, id: 6 },
  ]);

  const [taskStartDate, setTaskStartDate] = useState(
    new Date(
      baseDate.getFullYear(),
      baseDate.getMonth(),
      baseDate.getDate() - 1
    )
  );

  // const [loading, setLoading] = useState(true);

  const taskEndDate = new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate()
  );

  const fetchAllTaskTypeCounts = async () => {
    try {
      dispatch(
        getAllTaskTypeCounts({ startDate: taskStartDate, endDate: taskEndDate })
      );
    } catch (e) {
      console.error(e);
    }
  };

  const fetchPLC = async () => {
    try {
      dispatch(fetchPLCStatus());
    } catch (e) {
      console.error(e);
    }
  };

  const fetchData = async () => {
    try {
      await fetchPostToKeymasData()
        .then(
          async () =>
            await fetchUpsData().then(
              async () =>
                await fetchDespatchData().then(
                  async () =>
                    await fetchAllTaskTypeCounts().then(
                      async () =>
                        await fetchPLC().then(
                          async () =>
                            await fetchDataFromKeymas().then(
                              async () => await fetchDirectoryStatuses()
                            )
                        )
                    )
                )
            )
        )
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUpsData = async () => {
    const fetchLogDnaUpsData = async () => {
      dispatch(
        fetchLogDnaUpsInfo({
          hosts: `${REACT_APP_DESPATCH_UPS_HOST}`, // for local/staging
          apps: `${REACT_APP_UPS_APP}`,
        })
      );
    };
    if (
      currentTime.isBetween(
        operationTimesUps.startDate,
        operationTimesUps.endDate
      )
    ) {
      dispatch(setOperationTimesUps({ ...operationTimesUps, offline: false }));
      await fetchLogDnaUpsData().then(async () =>
        dispatch(getLastLabelGeneratedDate())
      );
      // .then(async () => dispatch(fetchLogopakDirectoryStatus())); // pass in environemnt type);
      // .then(() => {
      //   dispatch(
      //     fetchLogDnaUpsError({
      //       hosts: `${REACT_APP_GENERIC_LOGDNA_MONITORING_HOST}`,
      //     })
      //   );
      // })
    } else {
      dispatch(setOperationTimesUps({ ...operationTimesUps, offline: true }));
      dispatch(setUpsLoading(false));
    }
  };

  const fetchDirectoryStatuses = async () => {
    const fetchLogopakDirectory = async () =>
      dispatch(fetchLogopakDirectoryStatus());
    try {
      await fetchLogopakDirectory().then(() =>
        dispatch(fetchCheckDespatchPrintDirectory())
      );
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDespatchData = async () => {
    const fetchDespatchInfo = async () => {
      dispatch(
        fetchLogDnaDespatchInfo({
          hosts: `${REACT_APP_DESPATCH_UPS_HOST}`, // for local/staging
          apps: `${REACT_APP_DESPATCH_APP}`,
          query: `${REACT_APP_DESPATCH_QUERY}`,
        })
      );
    };
    if (
      currentTime.isBetween(
        operationTimesDespatch.startDate,
        operationTimesDespatch.endDate
      )
    ) {
      dispatch(
        setOperationTimesDespatch({ ...operationTimesDespatch, offline: false })
      );
      await fetchDespatchInfo()
        .then(() => dispatch(fetchDespatchNoteLastCreatedLabel()))
        .catch((e) => console.error(e)); // will require network credentials scripts to be added);

      // .then(() => {
      //   dispatch(
      //     fetchLogDnaDespatchError({
      //       hosts: `${REACT_APP_GENERIC_LOGDNA_MONITORING_HOST}`,
      //     })
      //   );
      // })
    } else {
      dispatch(
        setOperationTimesDespatch({ ...operationTimesDespatch, offline: true })
      );
      dispatch(setDespatchLoading(false));
    }
  };

  const fetchPostToKeymasData = async () => {
    if (
      currentTime.isBetween(
        operationTimesDataToKeymas.startDate,
        operationTimesDataToKeymas.endDate
      )
    ) {
      dispatch(
        setOperationTimesDataToKeymas({
          ...operationTimesDataToKeymas,
          offline: false,
        })
      );
      dispatch(
        fetchLogDnaKeymasInfoLogs({
          apps: `${REACT_APP_KEYMAS_APP}`,
          query: `${REACT_APP_QUERY_DATA_TO_KEYMAS}`,
        })
      )
        // .then(() => {
        //   dispatch(
        //     fetchLogDnaKeymasErrorLogs({
        //       apps: "wapp-api-staging",
        //       hosts: `wapp-api-staging-`,
        //       levels: "ERROR",
        //       query: "KeymasRequestLogs",
        //     }) // based on a timeframe rather than an error log
        //   );
        // })
        .then(() => {
          dispatch(getLastUpdateForDataToKeymas());
        });
    } else {
      dispatch(setDataToKeymasLogdnaLoading(false));
      dispatch(
        setOperationTimesDataToKeymas({
          ...operationTimesDataToKeymas,
          offline: true,
        })
      );
    }
  };

  const fetchDataFromKeymas = async () => {
    if (
      currentTime.isBetween(
        operationTImesDataFromKeymas.startDate,
        operationTImesDataFromKeymas.endDate
      )
    ) {
      dispatch(
        setOperationTimesDataFromKeymas({
          ...operationTImesDataFromKeymas,
          offline: false,
        })
      );
      dispatch(
        fetchLogDnaKeymasInfoLogs({
          apps: `${REACT_APP_KEYMAS_APP}`,
          query: `${REACT_APP_QUERY_DATA_FROM_KEYMAS}`,
        })
      );
      // .then(() => {
      //   dispatch(
      //     fetchLogDnaKeymasErrorLogs({
      //       apps: "wapp-api-staging",
      //       hosts: `wapp-api-staging-`,
      //       levels: "Error",
      //       query: "Error checking for Keymas updates RequestError",
      //     })
      //   );
      // });
    } else {
      dispatch(setDataToKeymasLogdnaLoading(false));
      dispatch(
        setOperationTimesDataFromKeymas({
          ...operationTImesDataFromKeymas,
          offline: true,
        })
      );
    }
  };

  const handleFullWidth = (widthItem) => {
    const tempFullWidth = [...toggleFullWidth];

    tempFullWidth.forEach((item) => {
      if (item.id === widthItem.id) {
        if (widthItem.toggled === false) {
          widthItem.toggled = true;
        } else {
          widthItem.toggled = false;
        }
        item = widthItem;
      }
      if (item.id !== widthItem.id) {
        item.toggled = false;
        if (item.hidden === false) {
          item.hidden = true;
        } else {
          item.hidden = false;
        }
      }
    });
    setToggleFullWidth([...tempFullWidth]);
  };

  useEffect(() => {
    const fetchAllMonitorData = async () => {
      try {
        await fetchData();
      } catch (e) {
        console.error(e);
      }
    };
    fetchAllMonitorData();
  }, []);

  useEffect(() => {
    setIsSelectedAnalytics(false);
    setIsSelectedSettings(false);
    setIsSelectedSearch(false);
    setIsSelectedBarcodeSearch(false);
    setIsSelectedMonitoring(true);
    const fetchAllMonitorData = async () => {
      try {
        await fetchData();
      } catch (e) {
        console.error(e);
      }
    };

    const fetchLogsInterval = setInterval(async () => {
      await fetchAllMonitorData();
    }, 60000);

    return () => {
      clearInterval(fetchLogsInterval);
    };
  });

  return (
    <Grid container spacing={2} className={classes.monitoringMainContainer}>
      <KeymasMonitoringPageSection
        data={keymasPostLogData}
        header={'Data to Keymas'}
        logHeader={'Log data to Keymas'}
        labelRelated={false}
        toggleFullWidthBoxes={toggleFullWidth[0]}
        setToggleFullWidthBoxes={handleFullWidth}
        lastGeneratedLabel={lastItemToKeymas}
        lastGeneratedHeader={'Last sent to Keymas: '}
        dataLoading={dataToKeymasLogdnaLoading}
        operationTimes={operationTimesDataToKeymas}
        loading={loading}
      />
      <KeymasMonitoringPageSection
        data={keymasLogData}
        header={'Keymas monitoring data'}
        logHeader={'Keymas log data'}
        labelRelated={false}
        toggleFullWidthBoxes={toggleFullWidth[2]}
        setToggleFullWidthBoxes={handleFullWidth}
        dataLoading={dataToKeymasLogdnaLoading}
        operationTimes={operationTImesDataFromKeymas}
        loading={loading}
      />
      <KeymasMonitoringPageSection
        data={upsLabelGeneratorInfo}
        header={'Label generator monitoring'}
        logHeader={'Log data'}
        loading={loading}
        lastGeneratedLabel={lastGeneratedLabelValueUps}
        lastGeneratedHeader={'Last label generated: '}
        requireDirectoryCheck={true}
        status={logopackStatus}
        directoryLoading={logopackLoading}
        toggleFullWidthBoxes={toggleFullWidth[1]}
        setToggleFullWidthBoxes={handleFullWidth}
        operationTimes={operationTimesUps}
      />
      <KeymasMonitoringPageSection
        data={DespatchNoteGeneratorInfo}
        header={'Despatch note monitoring'}
        logHeader={'Despatch note logs'}
        // loading={despatchCodeLoading}
        lastGeneratedLabel={despatchNoteLastGeneratedLabel}
        lastGeneratedHeader={'Last note generated: '}
        requireDirectoryCheck={true}
        status={despatchPrintDirectoryStatus}
        directoryLoading={despatchDirectoryLoading}
        toggleFullWidthBoxes={toggleFullWidth[3]}
        setToggleFullWidthBoxes={handleFullWidth}
        operationTimes={operationTimesDespatch}
        loading={loading}
      />
      <KeymasMonitoringPageSection
        plcData={plcStatus}
        header={'PLC status'}
        toggleFullWidthBoxes={toggleFullWidth[4]}
        setToggleFullWidthBoxes={handleFullWidth}
        operationTimes={operationTimesDespatch}
        plcLoading={plcLoading}
        dataLoading={plcLoading}
        loading={loading}
      />
      <KeymasMonitoringPageSection
        header={'Task Data Monitoring (Last 24 Hrs)'}
        toggleFullWidthBoxes={toggleFullWidth[5]}
        setToggleFullWidthBoxes={handleFullWidth}
        operationTimes={operationTimesDespatch}
        processedCountTotal={processedCountTotal}
        processingCountTotal={processingCountTotal}
        errorCountTotal={errorCountTotal}
        // taskDataLoading={loading}

        dataLoading={loading}
      />
    </Grid>
  );
};

export default MonitoringView;
