import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  Modal,
  IconButton,
  Icon,
} from '@material-ui/core';
import AddCronJob from './AddCronJob';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConfigSchedule } from '../../store/reducers/slices/configSlice';
import ButtonReactTable from '../tables/ButtonReactTable';
import DefaultColumnFilter from '../tables/ReactTable/DefaultColumnFilter';
import SelectColumnFilter from '../tables/ReactTable/SelectColumnFilter';

export default function ManageSchedule() {
  const dispatch = useDispatch();
  const { scheduleData } = useSelector((state) => state.manageConfigData);

  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [cronMode, setCronMode] = useState('');

  const columns = [
    {
      accessor: 'name',
      id: 'name',
      Header: 'Name',
      Filter: DefaultColumnFilter,
    },
    {
      accessor: 'schedule',
      id: 'schedule',
      Header: 'Schedule (Cron)',
      disableFilters: true,
    },
    {
      accessor: (originalRow) => (originalRow['active'] ? 'Active' : 'Offline'),
      id: 'active',
      Header: 'Active',
      Filter: SelectColumnFilter,
    },
    {
      accessor: 'Edit',
      id: 'Edit',
      Header: 'Edit',
      disableFilters: true,
      disableSortBy: true,
      Cell: (params) => (
        <strong>
          <IconButton onClick={() => editCronJob(params.row)}>
            <Icon className='fa fa-edit' />
          </IconButton>
        </strong>
      ),
    },
  ];

  const getScheduleData = useCallback(async () => {
    dispatch(fetchConfigSchedule());
  }, [dispatch]);

  useEffect(() => {
    if (scheduleData.length === 0) {
      getScheduleData();
    }
  }, [getScheduleData, scheduleData.length]);

  const handleClose = () => setShowEdit(false);

  const editCronJob = (item) => {
    setCronMode('Edit');
    setEditData(item.original);
    setShowEdit(true);
  };

  const createJob = () => {
    setCronMode('Add');
    setEditData({ name: '', schedule: '', active: true });
    setShowEdit(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    p: 4,
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100%'
        >
          <Box marginBottom='1em'>
            <Typography component={'span'} variant='h5'>
              Schedule Manager:
            </Typography>
          </Box>
          {!scheduleData || scheduleData.length === 0 ? (
            <Typography variant='h5'>Could not connect to Database</Typography>
          ) : (
            <ButtonReactTable
              data={scheduleData}
              columns={columns}
              id={'schedule-table'}
              initialState={{
                pageSize: 10,
                sortBy: [{ id: 'name', desc: false }],
              }}
              tableName='scheduleTable'
              persistState
            />
          )}
          <Box display='flex' flexDirection='column' marginTop='2em'>
            <Button
              variant='contained'
              color='primary'
              onClick={createJob}
              disabled={!scheduleData || scheduleData.length === 0}
            >
              Create Cron Job
            </Button>
          </Box>
        </Box>
      </Grid>
      <Modal
        open={showEdit}
        onClose={handleClose}
        aria-labelledby='edit cron job'
      >
        <Box sx={modalStyle}>
          <AddCronJob
            cronJob={editData}
            mode={cronMode}
            closeModal={handleClose}
            getScheduleData={getScheduleData}
          />
        </Box>
      </Modal>
    </>
  );
}
