import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  COUNT_TASKS,
  GET_ALL_COUNT_TASKS,
  GET_ALL_TASK_DATA,
  GET_COUNT_TASKS,
  GET_LAST_UPDATED,
  GET_TASK_TYPES,
  RESENDTASKQUEUEITEM,
} from '../../types';
import axios from 'axios';
import format from 'date-fns/format';

const DATE_FORMAT = 'yyyy-MM-dd';

const { REACT_APP_API_BASE_URI } = process.env;
const params = {
  headers: {
    deviceToken: '0e07b48d-b30f-4e02-b554-f23aad4c4b35',
    'Access-Control-Allow-Origin': '*',
    'x-jwt-token':
      'eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VVVUlEIjoiZjBmOTkxZGYtMjY3Zi0zMzUxLThhNmYtZjg1MTE4M2YwMzhjIiwicGVybWlzc2lvbnMiOlt7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BTExPQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjYyfSx7InBlcm1pc3Npb25fbmFtZSI6IkVQTV9BUFAiLCJwZXJtaXNzaW9uX2lkIjo2MX0seyJwZXJtaXNzaW9uX25hbWUiOiJXQVJFSE9VU0VfT1BFUkFUSU9OUyIsInBlcm1pc3Npb25faWQiOjY3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFMTCIsInBlcm1pc3Npb25faWQiOjN9LHsicGVybWlzc2lvbl9uYW1lIjoiUEhPVE9fUElDS1MiLCJwZXJtaXNzaW9uX2lkIjoxNjJ9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6MTAyfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0FQUCIsInBlcm1pc3Npb25faWQiOjEwMX0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9BUElfU1RBVFVTIiwicGVybWlzc2lvbl9pZCI6MTEzfSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX0JSQU5DSCIsInBlcm1pc3Npb25faWQiOjEwOH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9FUkVDRUlQVFMiLCJwZXJtaXNzaW9uX2lkIjoxMDl9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fRVJFQ0VJUFRTX0NPTkZJRyIsInBlcm1pc3Npb25faWQiOjExMH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9HUkFQSFMiLCJwZXJtaXNzaW9uX2lkIjoxMDN9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVkiLCJwZXJtaXNzaW9uX2lkIjoxMTF9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fUFVMU0VXQVlfTk9USUZJQ0FUSU9OUyIsInBlcm1pc3Npb25faWQiOjExMn0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVCIsInBlcm1pc3Npb25faWQiOjEwNH0seyJwZXJtaXNzaW9uX25hbWUiOiJBRE1JTl9TQUJPVF9DT05GSUciLCJwZXJtaXNzaW9uX2lkIjoxMDV9LHsicGVybWlzc2lvbl9uYW1lIjoiRVBNX1BJQ0tJTkciLCJwZXJtaXNzaW9uX2lkIjo2M30seyJwZXJtaXNzaW9uX25hbWUiOiJFUE1fUkVQT1JUSU5HIiwicGVybWlzc2lvbl9pZCI6NjR9LHsicGVybWlzc2lvbl9uYW1lIjoiQURNSU5fU05fQ09ORklHIiwicGVybWlzc2lvbl9pZCI6MTA3fSx7InBlcm1pc3Npb25fbmFtZSI6IkFETUlOX1NOIiwicGVybWlzc2lvbl9pZCI6MTA2fV19.7mZNEGYGhUxHpZF-D5UBqkv7BRm0g6HgesQ2EXBudds',
  },
};

export const getTaskData = createAsyncThunk(
  GET_ALL_TASK_DATA,
  async (data, { rejectWithValue }) => {
    const { startDate, endDate } = data;
    const tempStartDate = format(startDate, DATE_FORMAT);
    const adjustEndDate = new Date(endDate);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);
    const tempEndDate = format(endDate, DATE_FORMAT);
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskData?${
          tempStartDate && tempEndDate
            ? `start_date=${tempStartDate}&end_date=${tempEndDate}`
            : ''
        }`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('unable to get data.');
      }

      return response.data.data[0];
    } catch (e) {
      console.error(e);
    }
  }
);

export const getTotalTaskCount = createAsyncThunk(
  GET_ALL_COUNT_TASKS,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskStats`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('unable to get data');
      }

      return response.data;
    } catch (e) {
      console.error(e);
    }
  }
);

export const getAllTaskTypeCounts = createAsyncThunk(
  GET_COUNT_TASKS,
  async (data, { rejectWithValue }) => {
    const { startDate, endDate } = data;
    const tempStartDate = format(startDate, DATE_FORMAT);
    const adjustEndDate = new Date(endDate);
    adjustEndDate.setDate(adjustEndDate.getDate() + 1);
    const tempEndDate = format(endDate, DATE_FORMAT);

    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getAllTaskTypeCounts?start_date=${tempStartDate}&end_date=${tempEndDate}`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('unable to get data');
      }

      return response.data.data;
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchLastUpdated = createAsyncThunk(
  GET_LAST_UPDATED,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getLastUpdated`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('unable to get data');
      }

      return response.data.lastUpdated[0].lastCheckForUpdates;
    } catch (err) {
      console.error(err);
    }
  }
);

export const fetchAllTaskTypes = createAsyncThunk(
  GET_TASK_TYPES,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/getTaskTypes`,
        params
      );

      if (response.status === 200) {
        console.log(response.status);
      } else {
        return rejectWithValue('unable to get data');
      }
      return response.data.types;
    } catch (err) {
      console.error(err);
    }
  }
);

export const resendTaskQueueItem = createAsyncThunk(
  RESENDTASKQUEUEITEM,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_BASE_URI}keymas/dashboard/resendTaskQueueItem`,
        data,
        params
      );

      if (response.status === 200) {
        return response.status;
      } else {
        return rejectWithValue('Unable to connect');
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue('Unable to connect');
    }
  }
);

const dashboardDataSlice = createSlice({
  name: COUNT_TASKS,
  initialState: {
    processedCountTotal: '',
    processedCount: '',
    processingCountTotal: '',
    processingCount: '',
    errorCountTotal: '',
    errorCount: '',
    lastUpdated: new Date(),
    taskTypes: [],
    loading: false,
    allTotalTaskCounts: [],
    toastError: false,
    toastWarning: false,
    selectChartDataRange: 1, // hour by default (1 = hour / 2 = day / 3 = week)
    selectedTaskType: 0, // overall total by default (0 = overall total / 1 = supplier / 2 = product etc...)
    selectedTaskName: '',
    taskTypesWithCounts: [],
    totalTaskTypeCounts: [],
    dateReset: false,
  },
  reducers: {
    setTaskTypesWithCounts: (state, action) => {
      state.taskTypesWithCounts = action.payload;
    },

    setSelectChartDataRange: (state, action) => {
      state.selectChartDataRange = action.payload;
    },

    resetCounts: (state) => {
      state.processedCount = 0;
      state.processingCount = 0;
      state.errorCount = 0;
    },

    setSelectedTaskType: (state, action) => {
      state.selectedTaskType = action.payload;
      state.taskTypesWithCounts?.forEach((item) => {
        if (item.task_type_id === action.payload) {
          // proceed to push to processedTotal etc etc.
          state.processedCount = item.processed;
          state.processingCount = item.processing;
          state.errorCount = item.errors;
        } else if (action.payload === 0) {
          state.processedCount = 0;
          state.processingCount = 0;
          state.errorCount = 0;
        } else if (action.payload !== 0 && item.processed === 0) {
          state.processedCountTotal = 0;
          state.processingCountTotal = 0;
          state.errorCountTotal = 0;
        }
      });
    },

    setSelectedTaskName: (state, action) => {
      state.selectedTaskName = action.payload;
    },

    setDateReset: (state, action) => {
      state.dateReset = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllTaskTypeCounts.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.loading = false;
        state.totalTaskTypeCounts = action.payload;
        action.payload?.forEach((item) => {
          if (item[0]?.processed !== undefined) {
            state.processedCountTotal = item[0].processed;
          }
          if (item[0]?.processing !== undefined) {
            state.processingCountTotal = item[0].processing;
          }

          if (item[0]?.errors !== undefined) {
            state.errorCountTotal = item[0].errors;
          }
        });
      } else if (action.payload?.length === 0 || action.payload === undefined) {
        state.toastError = true;
      }
    });

    builder.addCase(getAllTaskTypeCounts.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllTaskTypeCounts.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchLastUpdated.fulfilled, (state, action) => {
      state.loading = false;
      state.lastUpdated = action.payload;
    });

    builder.addCase(fetchLastUpdated.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchLastUpdated.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTotalTaskCount.fulfilled, (state, action) => {
      state.loading = false;
      state.allTotalTaskCounts = action.payload.taskStats;
    });

    builder.addCase(getTotalTaskCount.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTotalTaskCount.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchAllTaskTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.taskTypes = action.payload;
    });

    builder.addCase(fetchAllTaskTypes.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchAllTaskTypes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getTaskData.fulfilled, (state, action) => {
      state.loading = false;
      state.taskTypesWithCounts = action.payload;
      if (state.selectedTaskType > 0) {
        const currentTaskType = action.payload?.filter(
          (task) => task.task_type_id === state.selectedTaskType
        )[0];
        state.processedCount = currentTaskType.processed;
        state.processingCount = currentTaskType.processing;
        state.errorCount = currentTaskType.errors;
      }
    });

    builder.addCase(getTaskData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getTaskData.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  setSelectChartDataRange,
  setSelectedTaskType,
  setSelectedTaskName,
  setTaskTypesWithCounts,
  setDateReset,
  resetCounts,
} = dashboardDataSlice.actions;
export default dashboardDataSlice.reducer;
