import React, { useEffect, useState, useCallback } from 'react';
import './config.css';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import Toast from '../toast/Toast';
import {
  fetchQueueAddSearch,
  postQueueAddSearch,
  displayItemsQueueAlert,
  checkIfQueued,
} from '../../store/reducers/slices/addTaskQueueSlice';
import { useDispatch, useSelector } from 'react-redux';
import ButtonReactTable from '../tables/ButtonReactTable';
import SelectColumnFilter from '../tables/ReactTable/SelectColumnFilter';

export default function AddToQueue({
  search,
  taskType,
  handleClose,
  handleToastClose,
  toastWarning,
  setToastWarning,
  searchData,
}) {
  const dispatch = useDispatch();

  const { searchQueueData } = useSelector((state) => state.manageQueueAddData);

  const [stateRows, setStateRows] = useState([]);
  const [selected, setSelected] = useState({});

  const changeRowData = (event, data) => {
    let checked = event.target.checked;
    let newArray = [];
    if (checked === true) {
      newArray = [...stateRows, { fkID: data.fkID, Record: data.Record }];
      setSelected({ ...selected, [data.fkID]: checked });
    } else {
      newArray = stateRows.filter((row) => row.fkID !== data.fkID);
      const { [data.fkID]: removed, ...other } = selected;
      setSelected(other);
    }
    setStateRows(newArray);
  };

  const columns = [
    {
      accessor: 'checkbox',
      id: 'checkbox',
      Header: '',
      disableFilters: true,
      Cell: (params) => (
        <strong>
          <Checkbox
            onClick={(event) => changeRowData(event, params.row.values)}
            checked={selected[params.row.values.fkID] === true}
          />
        </strong>
      ),
    },
    {
      accessor: 'fkID',
      id: 'fkID',
      Header: 'Id',
      disableFilters: true,
    },
    {
      accessor: 'Record',
      id: 'Record',
      Header: 'Record',
      Filter: SelectColumnFilter,
    },
  ];

  const addToQueue = (e) => {
    if (stateRows.length > 0) {
      dispatch(
        //check if row data is queued already
        checkIfQueued({
          task_type_id: taskType,
          rows_data: stateRows,
        })
      ).then(() => {
        dispatch(postQueueAddSearch({ taskType: taskType })).then(() => {
          dispatch(displayItemsQueueAlert());
          handleClose();
        });
      });
    }
  };

  const getSearchData = useCallback(() => {
    dispatch(
      fetchQueueAddSearch({
        search: search,
        taskType: taskType,
      })
    );
  }, [dispatch, search, taskType]);

  useEffect(() => {
    getSearchData();
  }, [getSearchData]);

  return (
    <Container fixed>
      <Grid
        item
        md={12}
        xs={12}
        style={{
          boxShadow: 'rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '0px solid rgba(0, 0, 0, 0.125)',
          borderRadius: '1rem',
          padding: '30px',
          minWidth: '600px',
        }}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100%'
        >
          <Typography
            variant='h4'
            style={{ marginLeft: '10px', marginBottom: '40px' }}
          >
            Add to Queue
          </Typography>
          {searchQueueData.length === 0 ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', margin: '50px' }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <ButtonReactTable
                data={searchQueueData}
                columns={columns}
                id={'add-to-queue'}
                initialState={{
                  pageSize: 10,
                  sortBy: [{ id: 'fkID', desc: false }],
                }}
                tableName='addToQueue'
              />
              <Box display='flex' justifyContent='flex-end' marginTop='1em'>
                <strong>
                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    onClick={(e) => addToQueue(e)}
                    disabled={stateRows.length === 0 ? true : false}
                  >
                    Add to queue
                  </Button>
                </strong>
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Toast
        selectedToast={toastWarning}
        handleToastClose={(e) => handleToastClose(e)}
        alertType='error'
        message='Failed to load data.'
      />
      <Toast />
    </Container>
  );
}
