import React from 'react'
import { TextField } from '@material-ui/core'

const DefaultColumnFilter = ({ column: { setFilter, filterValue } }) => {
  return (
    <TextField
      fullWidth
      onChange={e => setFilter(e.target.value || undefined)}
      value={filterValue || ''}
    />
  )
}

export default DefaultColumnFilter